import React, { Component } from 'react'
import moment from 'moment'
import ReactTable from 'react-table'
import { Grid, Row, Col, Badge, Modal } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Card from 'components/Card/Card.jsx'
import axios from 'axios'
import UserContext from 'context/Context'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { ROLES } from 'config/roles'
import Dropdown from 'components/Dropdown/Dropdown.jsx'
const _ = require('underscore')

class Visits extends Component {
  static contextType = UserContext
  state = {
    data: [],
    loading: true,
    page: 0,
    pages: 0,
    pageSize: 20,
    filters: {},
    sort: { createdAt: -1 },
    to: [],
    modalNotes: false,
    modalNotesText: '',
    deletingVisit: false,
    csvData: [],
    xlsData: []
  }

  componentDidMount () {
    this.csvLink = React.createRef()
    document.body.classList.add('sidebar-mini')

    this.updateUsers()
  }

  componentWillUnmount () {
    document.body.classList.remove('sidebar-mini')
  }

  updateUsers = () => {
    this.setState({ loading: true })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`,
        {
          count: true,
          filters: this.state.filters
        }
      )
      .then(res => {
        this.setState({
          pages: parseInt(res.data.results / this.state.pageSize) + 1
        })
      })

    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`,
        {
          filters: this.state.filters,
          page: this.state.page + 1,
          pageSize: this.state.pageSize,
          sort: this.state.sort
        }
      )
      .then(res => {
        this.setState({
          data: res.data.results,
          loading: false
        })
      })
  }

  deleteVisit = id => {
    this.setState({ deletingVisit: id })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/deleteVisit`,
        {
          id: id
        }
      )
      .then(res => {
        this.setState(
          {
            deletingVisit: false
          },
          () => this.updateUsers()
        )
      })
  }

  getColumnWidth = (accessor, headerText) => {
    const maxWidth = 400
    const magicSpacing = 9
    const cellLength = Math.max(
      ...this.context.filteredUsers.map(
        row => (`${row[accessor]}` || '').length
      ),
      headerText.length
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  defaultDropdown = () => {
    return {
      value: 'all',
      label: 'All'
    }
  }

  renderDropdownPlaceholder = (
    key,
    { filter = { value: this.defaultDropdown() }, onChange },
    options
  ) => {
    options = options.concat([this.defaultDropdown()])
    let defaultValue = filter.value
    if (_.find(this.state.defaultFiltered, { id: key })) {
      defaultValue = _.find(options, {
        value: _.find(this.state.defaultFiltered, { id: key }).value
      })
    }
    return (
      <Dropdown
        value={defaultValue}
        onChange={value => onChange(value)}
        placeholder='Buscar...'
        options={options}
      />
    )
  }

  render () {
    return (
      <div className='main-content'>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <ReactTable
                    previousText='Previous'
                    nextText='Next'
                    loadingText='Loading...'
                    noDataText='No visits'
                    pageText='Page'
                    ofText='of'
                    rowsText='visit'
                    data={this.state.data}
                    loading={this.state.loading}
                    filterable
                    manual
                    page={this.state.page}
                    pages={this.state.pages}
                    pageSize={this.state.pageSize}
                    defaultPageSize={20}
                    showPaginationBottom
                    className='-striped -highlight'
                    onSortedChange={newSorted =>
                      this.setState(
                        {
                          sort: {
                            [newSorted[0].id]:
                              newSorted[0].desc === false ? 1 : -1
                          },
                          loading: true
                        },
                        () => {
                          this.updateUsers()
                        }
                      )
                    }
                    onPageChange={page =>
                      this.setState({ page, loading: true }, () => {
                        this.updateUsers()
                      })
                    }
                    onPageSizeChange={(pageSize, page) =>
                      this.setState({ page, pageSize, loading: true }, () => {
                        this.updateUsers()
                      })
                    }
                    onFilteredChange={filters => {
                      const result = {}
                      let shouldWait = true
                      filters.forEach(filter => {
                        if (filter.id === 'user.visitorType') {
                          shouldWait = false
                          if (filter.value.value !== 'all') {
                            result[filter.id] = filter.value.value
                          }
                        } else {
                          result[filter.id] = {
                            $regex: filter.value,
                            $options: 'i'
                          }
                        }
                      })
                      this.setState({ filters: result })
                      const to = setTimeout(
                        () => {
                          this.setState({ to: [] })
                          this.updateUsers()
                        },
                        shouldWait ? 1000 : 0
                      )
                      const timeOuts = this.state.to
                      timeOuts.push(to)
                      this.setState({ to: timeOuts }, () => {
                        if (this.state.to.length > 1) {
                          window.clearTimeout(
                            this.state.to[this.state.to.length - 1]
                          )
                        }
                      })
                    }}
                    columns={[
                      {
                        Header: 'Date of visit',
                        accessor: 'createdAt',
                        width: 130,
                        Cell: ({ row }) => {
                          const date = moment(row.createdAt).format(
                            'DD/MM/YYYY HH:mm'
                          )
                          return date
                        },
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              fontFamily: 'Monospace',
                              letterSpacing: '-1px'
                            }
                          }
                        }
                      },
                      {
                        Header: 'Passport',
                        accessor: 'user.passport',
                        width: 120,
                        Cell: ({ row }) => {
                          return (
                            <Link to={'/user/' + row._original.user.passport}>
                              <Badge>{row._original.user.passport}</Badge>
                            </Link>
                          )
                        }
                      },
                      {
                        Header: 'Name',
                        accessor: 'user.name',
                        width: 240,
                        Cell: row => {
                          return (
                            <Link
                              style={{ fontWeight: 'bold', color: '#444' }}
                              to={'/user/' + row.original.user.passport}
                            >
                              {row.original.user.name}
                            </Link>
                          )
                        }
                      },
                      {
                        Header: 'Type',
                        accessor: 'user.visitorType',
                        width: 160,
                        Filter: ({ filter, onChange }) =>
                          this.renderDropdownPlaceholder(
                            'visitorType',
                            { filter, onChange },
                            this.context.terminal.userTypes.map(role => {
                              return {
                                label: ROLES[role].name,
                                value: role
                              }
                            })
                          ),
                        Cell: row => {
                          return (
                            <span
                              style={{
                                fontFamily: 'ITCFranklin',
                                color: ROLES[row.value].color
                              }}
                            >
                              <img
                                src={ROLES[row.value].image}
                                width='22'
                                style={{ borderRadius: '50%', margin: '4px' }}
                                alt='Roles'
                              />
                              {ROLES[row.value].name}
                            </span>
                          )
                        }
                      },
                      {
                        Header: 'Company',
                        accessor: 'user.company',
                        width: 140
                      },
                      {
                        Header: 'Reason',
                        accessor: 'reason',
                        width: 150,
                        Cell: row => {
                          if (row.value === 'visit') {
                            return 'Visit'
                          } else if (row.value === 'reception') {
                            return 'Reception/Delivery'
                          } else if (row.value === 'externalWorks') {
                            return 'External works'
                          } else if (row.value === 'shipOperations') {
                            return 'Ship operations'
                          }
                        }
                      },
                      {
                        Header: 'Contact department',
                        accessor: 'contactDepartment',
                        width: 180,
                        Cell: row => {
                          if (row.value === 'administration') {
                            return 'Administration'
                          } else if (row.value === 'maintenance') {
                            return 'Maintenance'
                          } else if (row.value === 'operations') {
                            return 'Operations'
                          }
                        }
                      },
                      {
                        Header: 'Delete',
                        style: {
                          textAlign: 'right'
                        },
                        filterable: false,
                        sortable: false,
                        Cell: row => {
                          return (
                            this.context.user.role === 'admin' && (
                              <Button
                                onClick={() => {
                                  this.deleteVisit(row.original._id)
                                }}
                                bsSize='xs'
                                simple
                              >
                                {this.state.deletingVisit ===
                                row.original._id ? (
                                  <i className='fa fa-spin fa-spinner' />
                                ) : (
                                  <i className='fa fa-close text-danger' />
                                )}
                              </Button>
                            )
                          )
                        }
                      }
                    ]}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ float: 'right' }}
                simple
                bsStyle='info'
                onClick={async () => {
                  const res = await axios.post(
                    `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`,
                    {
                      filters: this.state.filters,
                      sort: this.state.sort
                    }
                  )
                  this.setState(
                    {
                      xlsData: res.data.results.map(item => {
                        const itemCopy = item
                        itemCopy.name = item.user.name
                        itemCopy.passport = item.user.passport
                        itemCopy.visitorType = item.user.visitorType
                        itemCopy.company = item.user.company
                        delete itemCopy._id
                        delete itemCopy.user
                        delete itemCopy.timestamp
                        return itemCopy
                      })
                    },
                    () => {
                      console.log(this.state.xlsData)
                      const dataTypes = {
                        company: 'String',
                        contactDepartment: 'String',
                        createdAt: 'String',
                        name: 'String',
                        passport: 'String',
                        reason: 'String',
                        visitorType: 'String'
                      }
                      const emitXmlHeader = () => {
                        let headerRow = '<ss:Row>\n'
                        for (const colName in dataTypes) {
                          headerRow += '  <ss:Cell>\n'
                          headerRow += '    <ss:Data ss:Type="String">'
                          headerRow += colName + '</ss:Data>\n'
                          headerRow += '  </ss:Cell>\n'
                        }
                        headerRow += '</ss:Row>\n'
                        return (
                          '<?xml version="1.0"?>\n' +
                          '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
                          '<ss:Worksheet ss:Name="Sheet1">\n' +
                          '<ss:Table>\n\n' +
                          headerRow
                        )
                      }
                      const emitXmlFooter = () => {
                        return (
                          '\n</ss:Table>\n' +
                          '</ss:Worksheet>\n' +
                          '</ss:Workbook>\n'
                        )
                      }
                      const jsonToSsXml = jsonObject => {
                        let row
                        let col
                        let xml
                        const data =
                          typeof jsonObject !== 'object'
                            ? JSON.parse(jsonObject)
                            : jsonObject

                        xml = emitXmlHeader()

                        for (row = 0; row < data.length; row++) {
                          xml += '<ss:Row>\n'

                          for (col in data[row]) {
                            xml += '  <ss:Cell>\n'
                            xml +=
                              '    <ss:Data ss:Type="' + dataTypes[col] + '">'
                            xml += data[row][col] + '</ss:Data>\n'
                            xml += '  </ss:Cell>\n'
                          }

                          xml += '</ss:Row>\n'
                        }

                        xml += emitXmlFooter()
                        return xml
                      }
                      const url =
                        'data:text/xls;charset=utf-8,' +
                        encodeURIComponent(jsonToSsXml(this.state.xlsData))

                      const link = document.createElement('a')
                      link.style.display = 'none'
                      link.href = url
                      link.download = 'visits.xls'
                      document.body.appendChild(link)
                      link.click()

                      console.log('Success!')
                    }
                  )
                }}
              >
                Download XLS
              </Button>
            </Col>
            <Col>
              <Button
                style={{ float: 'right' }}
                simple
                bsStyle='info'
                onClick={async () => {
                  const res = await axios.post(
                    `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`,
                    {
                      filters: this.state.filters,
                      sort: this.state.sort
                    }
                  )
                  this.setState(
                    {
                      csvData: res.data.results.map(item => {
                        const itemCopy = item
                        itemCopy.name = item.user.name
                        itemCopy.passport = item.user.passport
                        itemCopy.visitorType = item.user.visitorType
                        itemCopy.company = item.user.company
                        delete itemCopy._id
                        delete itemCopy.user
                        delete itemCopy.timestamp
                        return itemCopy
                      })
                    },
                    () => {
                      this.csvLink.current.link.click()
                    }
                  )
                }}
              >
                Download CSV
              </Button>
              <CSVLink
                separator=';'
                data={this.state.csvData}
                filename='visits.csv'
                ref={this.csvLink}
                target='_blank'
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          backdrop='static'
          keyboard={false}
          show={this.state.modalNotes}
          onHide={() => this.setState({ modalNotes: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Visit notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalNotesText}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => this.setState({ modalNotes: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default Visits
