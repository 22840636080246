import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock
} from 'react-bootstrap'
import Dropdown from 'components/Dropdown/Dropdown.jsx'
import Card from 'components/Card/Card.jsx'
import UserContext from 'context/Context'
import Button from 'components/CustomButton/CustomButton.jsx'
import firebase from 'auth/Auth.jsx'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ReactTable from 'react-table'
import Select from 'react-select'
import Radio from 'components/CustomRadio/CustomRadio.jsx'
import { LANGUAGES } from 'config/languages'
import { ROLES } from 'config/roles'
const _ = require('underscore')

const levelDropDownOptions = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' }
]

class Questions extends Component {
  static contextType = UserContext

  state = {
    selectedLan: 'EN',
    data: [],
    loading: true,
    modalNewQuestion: false,
    deletingQuestion: false
  }

  componentDidMount () {
    firebase
      .firestore()
      .collection('terminals')
      .doc(this.context.user.terminal)
      .collection('questions')
      .onSnapshot(snapshot => {
        this.setState({
          loading: false,
          data: snapshot.docs.map(not => {
            return { ...not.data(), id: not.id }
          })
        })
      })
  }

  deleteQuestion = id => {
    this.setState({ deletingQuestion: true })
    firebase
      .firestore()
      .collection('terminals')
      .doc(this.context.user.terminal)
      .collection('questions')
      .doc(id)
      .delete()
      .then(() => {
        this.setState({ deletingQuestion: false })
      })
  }

  handleRadio = event => {
    const target = event.target
    this.setState({
      [target.name]: parseInt(target.value)
    })
  }

  renderEditable = cellInfo => {
    const edit = <Tooltip>Edit text</Tooltip>
    return (
      <OverlayTrigger placement='top' overlay={edit}>
        <div
          style={{ backgroundColor: '#fafafa' }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const data = [...this.state.data]
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML
            firebase
              .firestore()
              .collection('terminals')
              .doc(this.context.user.terminal)
              .collection('questions')
              .doc(cellInfo.original.id)
              .update({
                [cellInfo.column.id]: e.target.innerHTML
              })
            this.setState({ data })
          }}
          dangerouslySetInnerHTML={{
            __html: this.state.data[cellInfo.index][cellInfo.column.id]
          }}
        />
      </OverlayTrigger>
    )
  }

  renderEditableSub = (cellInfo, parent) => {
    const edit = <Tooltip>Edit text</Tooltip>
    return (
      <OverlayTrigger placement='top' overlay={edit}>
        <div
          style={{
            backgroundColor: '#fafafa',
            fontWeight: this.state.data[parent.index][
              'answers' + this.state.selectedLan
            ][cellInfo.index].correct
              ? 'bold'
              : 'normal'
          }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const data = [...this.state.data]
            data[parent.index]['answers' + this.state.selectedLan][
              cellInfo.index
            ].answer = e.target.innerHTML
            firebase
              .firestore()
              .collection('terminals')
              .doc(this.context.user.terminal)
              .collection('questions')
              .doc(parent.original.id)
              .update({
                ['answers' + this.state.selectedLan]: data[parent.index][
                  'answers' + this.state.selectedLan
                ]
              })
            this.setState({ data })
          }}
          dangerouslySetInnerHTML={{
            __html: this.state.data[parent.index][
              'answers' + this.state.selectedLan
            ][cellInfo.index].answer
          }}
        />
      </OverlayTrigger>
    )
  }

  setCorrect = (parent, row) => {
    const data = [...this.state.data]
    data[parent.index]['answers' + this.state.selectedLan].forEach(
      (value, index) => {
        data[parent.index]['answers' + this.state.selectedLan][
          index
        ].correct = false
      }
    )
    data[parent.index]['answers' + this.state.selectedLan][
      row.index
    ].correct = true
    firebase
      .firestore()
      .collection('terminals')
      .doc(this.context.user.terminal)
      .collection('questions')
      .doc(parent.original.id)
      .update({
        ['answers' + this.state.selectedLan]: data[parent.index][
          'answers' + this.state.selectedLan
        ]
      })
  }

  defaultDropdown = () => {
    return {
      value: 'all',
      label: 'All'
    }
  }

  renderDropdownPlaceholder = (
    key,
    { filter = { value: this.defaultDropdown() }, onChange },
    options
  ) => {
    options = options.concat([this.defaultDropdown()])
    let defaultValue = filter.value
    if (_.find(this.state.defaultFiltered, { id: key })) {
      defaultValue = _.find(options, {
        value: _.find(this.state.defaultFiltered, { id: key }).value
      })
    }
    return (
      <Dropdown
        value={defaultValue}
        onChange={value => onChange(value)}
        placeholder='Buscar...'
        options={options}
      />
    )
  }

  render () {
    return (
      <div className='main-content'>
        <Grid fluid>
          <Row>
            <Col style={{ textAlign: 'right' }} md={12}>
              <Button
                onClick={() => this.setState({ modalNewQuestion: true })}
                style={{
                  position: 'absolute',
                  top: '-18px',
                  right: '30px',
                  zIndex: 9
                }}
                bsStyle='warning'
                fill
                wd
              >
                <b>ADD QUESTION</b>
                <i className='fa fa-plus' />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title='Questions & Answers'
                content={
                  <ReactTable
                    previousText='Previos'
                    nextText='Next'
                    loadingText='Loading...'
                    noDataText='No questions'
                    pageText='Page'
                    ofText='of'
                    rowsText='Questions'
                    data={this.state.data}
                    loading={this.state.loading}
                    filterable={false}
                    defaultPageSize={15}
                    showPaginationBottom
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id
                      if (id === 'type' || id === 'level') {
                        if (filter.value.value === 'all') {
                          return true
                        } else {
                          return row[id] !== undefined
                            ? String(row[id]).startsWith(filter.value.value)
                            : true
                        }
                      } else {
                        return row[id] !== undefined
                          ? String(row[id]).startsWith(filter.value)
                          : true
                      }
                    }}
                    collapseOnDataChange={false}
                    className='-striped -highlight'
                    SubComponent={row => {
                      const data =
                        row.original['answers' + this.state.selectedLan]
                      return (
                        <div style={{ textAlign: 'right' }}>
                          <br />
                          <h6
                            style={{ marginLeft: '10px' }}
                            className='text-muted'
                          >
                            Language answers:
                            {Object.keys(LANGUAGES).map(lan => {
                              return (
                                <img
                                  key={lan}
                                  alt='flag'
                                  onClick={() =>
                                    this.setState({ selectedLan: lan })
                                  }
                                  style={{
                                    cursor: 'pointer',
                                    opacity:
                                      this.state.selectedLan === lan ? 1 : 0.2,
                                    margin: '0 10px'
                                  }}
                                  src={`https://flagcdn.com/w40/${LANGUAGES[
                                    lan
                                  ].flag.toLowerCase()}.png`}
                                />
                              )
                            })}
                          </h6>
                          <ReactTable
                            data={data}
                            sortable={false}
                            columns={[
                              {
                                Header: 'Correct',
                                accesor: 'correct',
                                width: 100,
                                filterable: false,
                                style: {
                                  textAlign: 'center'
                                },
                                Cell: row2 => {
                                  return row2.original.correct ? (
                                    <i className='text-success fa fa-check' />
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        this.setCorrect(row, row2)
                                      }}
                                      bsSize='xs'
                                      simple
                                    >
                                      <i className='fa fa-close text-danger' />
                                    </Button>
                                  )
                                }
                              },
                              {
                                Header: 'Answer',
                                accessor: 'answer',
                                style: {
                                  textAlign: 'left'
                                },
                                headerStyle: {
                                  textAlign: 'left'
                                },
                                Cell: info => this.renderEditableSub(info, row)
                              }
                            ]}
                          />
                        </div>
                      )
                    }}
                    columns={[
                      {
                        Header: 'User type',
                        accessor: 'type',
                        width: 200,
                        filterable: true,
                        style: {
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          textAlign: 'center'
                        },
                        Filter: ({ filter, onChange }) =>
                          this.renderDropdownPlaceholder(
                            'type',
                            { filter, onChange },
                            this.context.terminal.userTypes.map(role => {
                              return {
                                label: ROLES[role].name,
                                value: role
                              }
                            })
                          ),
                        Cell: row => {
                          return (
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='typeSelect'
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 })
                              }}
                              menuPortalTarget={document.body}
                              value={{
                                label: ROLES[row.value].name,
                                value: row.value
                              }}
                              onChange={e => {
                                firebase
                                  .firestore()
                                  .collection('terminals')
                                  .doc(this.context.user.terminal)
                                  .collection('questions')
                                  .doc(row.original.id)
                                  .update({
                                    type: e.value
                                  })
                              }}
                              options={this.context.terminal.userTypes.map(
                                role => {
                                  return {
                                    label: ROLES[role].name,
                                    value: role
                                  }
                                }
                              )}
                              placeholder='Select type'
                            />
                          )
                        }
                      },
                      {
                        Header: 'Level',
                        accessor: 'level',
                        width: 100,
                        filterable: true,
                        style: {
                          fontWeight: 'bold',
                          textAlign: 'center'
                        },
                        Filter: ({ filter, onChange }) =>
                          this.renderDropdownPlaceholder(
                            'level',
                            { filter, onChange },
                            levelDropDownOptions
                          ),
                        Cell: row => {
                          return (
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='levelSelect'
                              styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 })
                              }}
                              menuPortalTarget={document.body}
                              value={{ label: row.value, value: row.value }}
                              onChange={e => {
                                firebase
                                  .firestore()
                                  .collection('terminals')
                                  .doc(this.context.user.terminal)
                                  .collection('questions')
                                  .doc(row.original.id)
                                  .update({
                                    level: e.value
                                  })
                              }}
                              options={[
                                { value: 'A', label: 'A' },
                                { value: 'B', label: 'B' },
                                { value: 'C', label: 'C' },
                                { value: 'D', label: 'D' },
                                { value: 'E', label: 'E' }
                              ]}
                              placeholder='Select level'
                            />
                          )
                        }
                      },
                      ...Object.keys(LANGUAGES).map(lan => {
                        return {
                          Header: (
                            <span>
                              Question{' '}
                              <img
                                alt='flag'
                                style={{ marginLeft: '10px' }}
                                src={`https://flagcdn.com/w40/${LANGUAGES[
                                  lan
                                ].flag.toLowerCase()}.png`}
                              />
                            </span>
                          ),
                          accessor: 'question' + lan,
                          style: { 'white-space': 'unset' },
                          filterable: true,
                          sortable: false,
                          Cell: this.renderEditable
                        }
                      }),
                      {
                        expander: true,
                        Header: () => <strong>Answers</strong>,
                        width: 80,
                        Expander: ({ isExpanded, ...rest }) => (
                          <div>
                            {isExpanded ? (
                              <i className='text-muted fa fa-chevron-up' />
                            ) : (
                              <i className='text-muted fa fa-chevron-down' />
                            )}
                          </div>
                        ),
                        style: {
                          cursor: 'pointer',
                          fontSize: 16,
                          padding: '0',
                          textAlign: 'center',
                          userSelect: 'none'
                        }
                      },
                      {
                        Header: 'Delete',
                        style: {
                          textAlign: 'right'
                        },
                        filterable: false,
                        sortable: false,
                        Cell: row => {
                          return (
                            <Button
                              onClick={() => {
                                this.deleteQuestion(row.original.id)
                              }}
                              bsSize='xs'
                              simple
                            >
                              {this.state.deletingQuestion ===
                              row.original._id ? (
                                <i className='fa fa-spin fa-spinner' />
                              ) : (
                                <i className='fa fa-close text-danger' />
                              )}
                            </Button>
                          )
                        }
                      }
                    ]}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          backdrop='static'
          keyboard={false}
          show={this.state.modalNewQuestion}
          onHide={() => this.setState({ modalNewQuestion: false })}
        >
          <Formik
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const valuesToInsert = {
                level: values.level.value,
                type: values.type.value
              }

              Object.keys(LANGUAGES).forEach(lan => {
                if (values['question' + lan]) {
                  valuesToInsert['question' + lan] = values['question' + lan]
                } else {
                  valuesToInsert['question' + lan] = values.questionEN
                }

                if (
                  values['answer1' + lan] &&
                  values['answer2' + lan] &&
                  values['answer3' + lan] &&
                  values['answer4' + lan]
                ) {
                  valuesToInsert['answers' + lan] = [
                    {
                      answer: values['answer1' + lan],
                      correct: values['answerRadio' + lan] === 1
                    },
                    {
                      answer: values['answer2' + lan],
                      correct: values['answerRadio' + lan] === 2
                    },
                    {
                      answer: values['answer3' + lan],
                      correct: values['answerRadio' + lan] === 3
                    },
                    {
                      answer: values['answer4' + lan],
                      correct: values['answerRadio' + lan] === 4
                    }
                  ]
                } else {
                  valuesToInsert['answers' + lan] = [
                    {
                      answer: values.answer1EN,
                      correct: values.answerRadioEN === 1
                    },
                    {
                      answer: values.answer2EN,
                      correct: values.answerRadioEN === 2
                    },
                    {
                      answer: values.answer3EN,
                      correct: values.answerRadioEN === 3
                    },
                    {
                      answer: values.answer4EN,
                      correct: values.answerRadioEN === 4
                    }
                  ]
                }
              })

              await firebase
                .firestore()
                .collection('terminals')
                .doc(this.context.user.terminal)
                .collection('questions')
                .add(valuesToInsert)

              setSubmitting(false)
              this.setState({ modalNewQuestion: false, selectedLan: 'EN' })
              // resetForm()
            }}
            validationSchema={Yup.object().shape({
              questionEN: Yup.string().required(
                'Mandatory question in English.'
              ),
              answer1EN: Yup.string().required(
                'Answer 1 mandatory in English.'
              ),
              answer2EN: Yup.string().required(
                'Answer 2 mandatory in English.'
              ),
              answer3EN: Yup.string().required(
                'Answer 3 mandatory in English.'
              ),
              answer4EN: Yup.string().required(
                'Answer 4 mandatory in English.'
              ),
              answerRadioEN: Yup.number().required(
                'Select correct answer mandatory'
              ),
              level: Yup.string().required('Mandatory level.'),
              type: Yup.string().required('Mandatory level.')
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isValid,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title>New question</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <Row fluid>
                        <Col xs={12} md={6}>
                          <FormGroup
                            validationState={
                              errors.body && touched.body ? 'error' : ''
                            }
                          >
                            <ControlLabel style={{ width: '100%' }}>
                              Level
                            </ControlLabel>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='levelSelect'
                              value={values.level}
                              onChange={option =>
                                setFieldValue('level', option)
                              }
                              options={[
                                { value: 'A', label: 'A' },
                                { value: 'B', label: 'B' },
                                { value: 'C', label: 'C' },
                                { value: 'D', label: 'D' },
                                { value: 'E', label: 'E' }
                              ]}
                              placeholder='Select level'
                            />
                            {errors.level && touched.level && (
                              <>
                                <FormControl.Feedback />
                                <HelpBlock>{errors.level}</HelpBlock>
                              </>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup
                            validationState={
                              errors.body && touched.body ? 'error' : ''
                            }
                          >
                            <ControlLabel style={{ width: '100%' }}>
                              Type
                            </ControlLabel>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='typeSelect'
                              value={values.type}
                              onChange={option => setFieldValue('type', option)}
                              options={this.context.terminal.userTypes.map(
                                role => {
                                  return {
                                    label: ROLES[role].name,
                                    value: role
                                  }
                                }
                              )}
                              placeholder='Select type'
                            />
                            {errors.type && touched.type && (
                              <>
                                <FormControl.Feedback />
                                <HelpBlock>{errors.type}</HelpBlock>
                              </>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup
                        validationState={
                          errors.title && touched.title ? 'error' : ''
                        }
                      >
                        <ControlLabel style={{ width: '100%' }}>
                          <div>
                            Question
                            <div style={{ float: 'right' }}>
                              {Object.keys(LANGUAGES).map(lan => {
                                return (
                                  <img
                                    key={lan}
                                    alt='flag'
                                    onClick={() =>
                                      this.setState({ selectedLan: lan })
                                    }
                                    style={{
                                      cursor: 'pointer',
                                      opacity:
                                        this.state.selectedLan === lan
                                          ? 1
                                          : 0.2,
                                      margin: '0 10px'
                                    }}
                                    src={`https://flagcdn.com/w40/${LANGUAGES[
                                      lan
                                    ].flag.toLowerCase()}.png`}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </ControlLabel>
                        {Object.keys(LANGUAGES).map(lan => {
                          if (this.state.selectedLan === lan) {
                            return (
                              <>
                                <FormControl
                                  id={'question' + lan}
                                  value={values['question' + lan]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  rows='3'
                                  componentClass='textarea'
                                  placeholder={`Question in ${LANGUAGES[lan].name}...`}
                                />
                                {errors['question' + lan] &&
                                  touched['question' + lan] && (
                                    <>
                                      <FormControl.Feedback />
                                      <HelpBlock>
                                        {errors['question' + lan]}
                                      </HelpBlock>
                                    </>
                                  )}
                              </>
                            )
                          } else {
                            return null
                          }
                        })}
                      </FormGroup>
                      {[1, 2, 3, 4].map(answerIndex => {
                        return (
                          <FormGroup
                            key={answerIndex}
                            validationState={
                              errors.body && touched.body ? 'error' : ''
                            }
                          >
                            <ControlLabel style={{ width: '100%' }}>
                              <div>
                                <div style={{ display: 'inline-block' }}>
                                  <span style={{ marginRight: '10px' }}>
                                    Answer {answerIndex}
                                  </span>
                                  {Object.keys(LANGUAGES).map((lan, index) => {
                                    if (this.state.selectedLan === lan) {
                                      return (
                                        <Radio
                                          inline
                                          number={answerIndex + 'dr'}
                                          option={answerIndex}
                                          name={'answerRadio' + lan}
                                          onChange={e =>
                                            setFieldValue(
                                              'answerRadio' + lan,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          checked={
                                            values['answerRadio' + lan] ===
                                            answerIndex
                                          }
                                          label='Correct'
                                        />
                                      )
                                    } else {
                                      return null
                                    }
                                  })}
                                </div>
                                <div style={{ float: 'right' }}>
                                  {Object.keys(LANGUAGES).map(lan => {
                                    return (
                                      <img
                                        key={lan}
                                        alt='flag'
                                        onClick={() =>
                                          this.setState({ selectedLan: lan })
                                        }
                                        style={{
                                          cursor: 'pointer',
                                          opacity:
                                            this.state.selectedLan === lan
                                              ? 1
                                              : 0.2,
                                          margin: '0 10px'
                                        }}
                                        src={`https://flagcdn.com/w40/${LANGUAGES[
                                          lan
                                        ].flag.toLowerCase()}.png`}
                                      />
                                    )
                                  })}
                                </div>
                              </div>
                            </ControlLabel>
                            {Object.keys(LANGUAGES).map(lan => {
                              if (this.state.selectedLan === lan) {
                                return (
                                  <>
                                    <FormControl
                                      id={'answer' + answerIndex + lan}
                                      value={
                                        values['answer' + answerIndex + lan]
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder={`Answer ${answerIndex} in ${LANGUAGES[lan].name}...`}
                                    />
                                    {errors['answer' + answerIndex + lan] &&
                                      touched['answer' + answerIndex + lan] && (
                                        <>
                                          <FormControl.Feedback />
                                          <HelpBlock>
                                            {
                                              errors[
                                                'answer' + answerIndex + lan
                                              ]
                                            }
                                          </HelpBlock>
                                        </>
                                      )}
                                  </>
                                )
                              } else {
                                return null
                              }
                            })}
                          </FormGroup>
                        )
                      })}
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      disabled={!dirty || isSubmitting || !isValid}
                      type='submit'
                      bsStyle='warning'
                      fill
                      wd
                    >
                      <b>ADD </b>
                      <i className='fa fa-plus' />
                    </Button>
                  </Modal.Footer>
                </form>
              )
            }}
          </Formik>
        </Modal>
      </div>
    )
  }
}

export default Questions
