import React, { Component } from 'react'

import ReactTable from 'react-table'

import {
  Grid,
  Row,
  Col,
  Badge,
  Modal,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'

// Components
import Button from 'components/CustomButton/CustomButton.jsx'
import Dropdown from 'components/Dropdown/Dropdown.jsx'
import Card from 'components/Card/Card.jsx'
import { NewUserModal } from 'components/NewUserModal/NewUserModal'

// Context
import UserContext from 'context/Context'
// Router
import { Link } from 'react-router-dom'
// Packages
import { CSVLink } from 'react-csv'
import queryString from 'query-string'
import axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert'
import * as _ from 'underscore'
import moment from 'moment'

// Config
import { ROLES } from 'config/roles'

const momentBusinessDays = require('moment-business-days')

const dropDownOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

class Users extends Component {
  static contextType = UserContext

  constructor (props) {
    super(props)
    let filters = {}
    let defaultFiltered = {}
    try {
      const query = queryString.parse(this.props.location.search)
      filters = JSON.parse(query.filters)
      defaultFiltered = JSON.parse(query.defaultFiltered)
    } catch (err) {}

    this.state = {
      showNewUserModal: false,
      data: [],
      loading: true,
      page: 0,
      pages: 0,
      pageSize: 20,
      filters,
      defaultFiltered,
      sort: { createdAt: -1 },
      to: [],
      modalNotes: false,
      modalNotesText: '',
      deletingUser: false,
      csvData: [],
      xlsData: [],
      alert: null
    }
  }

  componentDidMount () {
    console.log(this.context)
    this.csvLink = React.createRef()
    this.xlsLink = React.createRef()
    document.body.classList.add('sidebar-mini')

    this.updateUsers()
  }

  componentWillUnmount () {
    document.body.classList.remove('sidebar-mini')
  }

  isCustomPassportPlaceholder = terminal => {
    return (
      this.context.terminal?.extensions &&
      this.context.terminal?.extensions?.passportPlaceholder
    )
  }

  updateUsers = () => {
    this.setState({ loading: true })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
        {
          count: true,
          filters: this.state.filters
        }
      )
      .then(res => {
        this.setState({
          pages: parseInt(res.data.results / this.state.pageSize) + 1
        })
      })

    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
        {
          filters: this.state.filters,
          page: this.state.page + 1,
          pageSize: this.state.pageSize,
          sort: this.state.sort
        }
      )
      .then(res => {
        this.setState({
          data: res.data.results,
          loading: false
        })
      })
  }

  deleteUser = user => {
    this.setState({ deletingUser: user.passport })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/deleteUser`,
        {
          passport: user.passport
        }
      )
      .then(res => {
        axios
          .post(
            'https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/push',
            {
              data: [
                {
                  to: user.pushId,
                  title: 'User deleted',
                  body: 'Your user has been deleted',
                  sound: 'default',
                  priority: 'high',
                  data: JSON.stringify({ logout: true })
                }
              ]
            }
          )
          .then(res => {
            console.log('Logout notification', res)
          })
        this.setState(
          {
            deletingUser: false
          },
          () => this.updateUsers()
        )
      })
  }

  getColumnWidth = (accessor, headerText) => {
    const maxWidth = 400
    const magicSpacing = 9
    const cellLength = Math.max(
      ...this.context.filteredUsers.map(
        row => (`${row[accessor]}` || '').length
      ),
      headerText.length
    )
    return Math.min(maxWidth, cellLength * magicSpacing)
  }

  isToday = someDate => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  getActivePenalties = penalties => {
    const activePenalties = []
    penalties.forEach(penalty => {
      let days = 0
      switch (penalty.repeat) {
        case 1:
          days = 0
          break
        case 2:
          days = 1
          break
        case 3:
          days = 5
          break
        case 4:
          days = 15
          break
        case 5:
          days = 9999
          break
        default:
          days = 0
          break
      }
      const penaltyUntil = momentBusinessDays(
        new Date(penalty.createdAt)
      ).businessAdd(days + 1)._d
      penalty = {
        ...penalty,
        penaltyUntil,
        permanent: days === 9999
      }
      const now = moment()
      if (
        days !== 0 &&
        now <= penaltyUntil &&
        !this.isToday(new Date(penalty.createdAt))
      ) {
        activePenalties.push(penalty)
      }
    })
    return activePenalties
  }

  defaultDropdown = () => {
    return {
      value: 'all',
      label: 'All'
    }
  }

  renderDropdownPlaceholder = (
    key,
    { filter = { value: this.defaultDropdown() }, onChange },
    options
  ) => {
    options = options.concat([this.defaultDropdown()])
    let defaultValue = filter.value
    if (_.find(this.state.defaultFiltered, { id: key })) {
      defaultValue = _.find(options, {
        value: _.find(this.state.defaultFiltered, { id: key }).value
      })
    }
    return (
      <Dropdown
        value={defaultValue}
        onChange={value => onChange(value)}
        placeholder='Buscar...'
        options={options}
      />
    )
  }

  render () {
    return (
      <div className='main-content'>
        {this.state.alert}
        <NewUserModal
          updateUsers={() => this.updateUsers()}
          onClose={() => this.setState({ showNewUserModal: false })}
          show={this.state.showNewUserModal}
        />
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <>
                    {this.context.terminal?.extensions?.customLogin && (
                      <Button
                        style={{
                          position: 'absolute',
                          top: '-20px',
                          right: '24px'
                        }}
                        type='submit'
                        bsStyle='warning'
                        fill
                        wd
                        onClick={() =>
                          this.setState({ showNewUserModal: true })}
                      >
                        <b>NEW USER </b>
                        <i className='fa fa-user' />
                      </Button>
                    )}
                    <ReactTable
                      style={{ marginTop: '20px' }}
                      previousText='Previous'
                      nextText='Next'
                      loadingText='Loading...'
                      noDataText='No users'
                      pageText='Page'
                      ofText='of'
                      rowsText='users'
                      data={this.state.data}
                      loading={this.state.loading}
                      filterable
                      manual
                      page={this.state.page}
                      pages={this.state.pages}
                      pageSize={this.state.pageSize}
                      defaultPageSize={20}
                      showPaginationBottom
                      className='-striped -highlight'
                      onSortedChange={newSorted =>
                        this.setState(
                          {
                            sort: {
                              [newSorted[0].id]:
                                newSorted[0].desc === false ? 1 : -1
                            },
                            loading: true
                          },
                          () => {
                            this.updateUsers()
                          }
                      )}
                      onPageChange={page =>
                        this.setState({ page, loading: true }, () => {
                          this.updateUsers()
                        })}
                      onPageSizeChange={(pageSize, page) =>
                        this.setState({ page, pageSize, loading: true }, () => {
                          this.updateUsers()
                        })}
                      filter={this.state.defaultFiltered}
                      onFilteredChange={filters => {
                        const result = {}
                        let shouldWait = true
                        filters.forEach(filter => {
                          if (['visitorType', 'test'].includes(filter.id)) {
                            shouldWait = false
                            if (filter.value.value !== 'all') {
                              result[filter.id] = filter.value.value
                            }
                          } else if (filter.id === 'activeSession') {
                            if (filter.value.value !== 'all') {
                              if (filter.value.value) {
                                result[filter.id] = true
                              } else {
                                result[filter.id] = { $in: [false, null] }
                              }
                            }
                          } else if (filter.id === 'penalties') {
                            if (filter.value.value !== 'all') {
                              result[filter.id + '.0'] = { $exists: true }
                            }
                          } else {
                            result[filter.id] = {
                              $regex: filter.value,
                              $options: 'i'
                            }
                          }
                        })
                        this.setState({ filters: result })
                        const to = setTimeout(
                          () => {
                            this.setState({ to: [] })
                            this.updateUsers()
                          },
                          shouldWait ? 1000 : 0
                        )
                        const timeOuts = this.state.to
                        timeOuts.push(to)
                        this.setState({ to: timeOuts }, () => {
                          if (this.state.to.length > 1) {
                            window.clearTimeout(
                              this.state.to[this.state.to.length - 1]
                            )
                          }
                        })
                      }}
                      columns={
                        this.context.user.terminal === 'vadoLigure'
                          ? [
                            {
                              Header: 'Created At',
                              accessor: 'createdAt',
                              width: 130,
                              Cell: ({ row }) => {
                                const date = moment(row.createdAt).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                                return date
                              },
                              getProps: (state, rowInfo, column) => {
                                return {
                                  style: {
                                    fontFamily: 'Monospace',
                                    letterSpacing: '-1px'
                                  }
                                }
                              }
                            },
                            {
                              Header:
                                  this.isCustomPassportPlaceholder() ||
                                  'Passport',
                              accessor: 'passport',
                              width: 120,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'passport' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              },
                              Cell: ({ row }) => (
                                <Link to={'/user/' + row.passport}>
                                  <Badge>{row.passport}</Badge>
                                </Link>
                              )
                            },
                            {
                              Header: 'Name',
                              accessor: 'name',
                              width: 240,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'name' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              },
                              Cell: row => {
                                return (
                                  <Link
                                    style={{
                                      fontWeight: 'bold',
                                      color: '#444'
                                    }}
                                    to={'/user/' + row.original.passport}
                                  >
                                    {row.value}
                                  </Link>
                                )
                              }
                            },
                            {
                              Header: 'Type',
                              accessor: 'visitorType',
                              width: 160,
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'visitorType',
                                  { filter, onChange },
                                  this.context.terminal.userTypes.map(
                                    role => {
                                      return {
                                        label: ROLES[role].name,
                                        value: role
                                      }
                                    }
                                  )
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <span
                                      style={{
                                        fontFamily: 'ITCFranklin',
                                        color: ROLES[row.value].color
                                      }}
                                    >
                                      <img
                                        src={ROLES[row.value].image}
                                        width='22'
                                        style={{
                                          borderRadius: '50%',
                                          margin: '4px'
                                        }}
                                        alt='user'
                                      />
                                      {ROLES[row.value].name}
                                    </span>
                                  )
                                }
                              }
                            },
                            {
                              Header: 'Company',
                              accessor: 'company',
                              width: 140,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'company' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              }
                            },
                            {
                              Header: 'Induction',
                              accessor: 'test',
                              width: 120,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'test',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <>
                                      <i
                                        style={{ color: '#87CB16' }}
                                        className='fa fa-check-circle'
                                      />
                                      <span style={{ color: '#87CB16' }}>Yes</span>
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <i
                                        style={{ color: '#FB404B' }}
                                        className='fa fa-times-circle'
                                      />
                                      <span style={{ color: '#FB404B' }}>No</span>
                                    </>
                                  )
                                }
                              }
                            },
                            {
                              Header: 'Penalty',
                              accessor: 'penalties',
                              width: 110,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'penalties',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.original.penalties) {
                                  const activePenalties = this.getActivePenalties(
                                    row.original.penalties
                                  )
                                  if (activePenalties.length > 0) {
                                    const max = activePenalties.reduce(
                                      function (prev, current) {
                                        return moment(prev.penaltyUntil) >
                                            moment(current.penaltyUntil)
                                          ? prev
                                          : current
                                      }
                                    )
                                    const edit = (
                                      <Tooltip>
                                        {max.permanent
                                          ? 'PERMANENT PENALTY'
                                          : 'Sanctioned up ' +
                                              moment(max.penaltyUntil).format(
                                                'DD/MM/YYYY'
                                              )}
                                      </Tooltip>
                                    )
                                    return (
                                      <OverlayTrigger
                                        placement='top'
                                        overlay={edit}
                                      >
                                        <Link
                                          style={{
                                            fontWeight: 'bold',
                                            color: '#444'
                                          }}
                                          to={
                                            '/user/' + row.original.passport
                                          }
                                        >
                                          <i
                                            style={{ color: '#EEAF30' }}
                                            className='fa fa-exclamation-circle'
                                          />
                                        </Link>
                                      </OverlayTrigger>
                                    )
                                  } else {
                                    return ''
                                  }
                                } else {
                                  return ''
                                }
                              }
                            },
                            {
                              Header: 'Active Session',
                              accessor: 'activeSession',
                              width: 140,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'activeSession',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <i
                                      style={{ color: '#87CB16' }}
                                      className='fa fa-circle'
                                    />
                                  )
                                } else {
                                  return (
                                    <i
                                      style={{ color: '#c4c4c4' }}
                                      className='fa fa-circle'
                                    />
                                  )
                                }
                              }
                            },

                            {
                              Header: 'RESET PASSWORD',
                              style: {
                                textAlign: 'left'
                              },
                              filterable: false,
                              sortable: false,
                              Cell: row => {
                                return (
                                  <Button
                                    style={{
                                      fontSize: '14px',
                                      lineHeight: 1.4,
                                      fontWeight: 400,
                                      color: '#FD642B',
                                      marginLeft: '4rem'
                                    }}
                                    onClick={() => {
                                      axios
                                        .post(
                                            `https://us-central1-apm-terminals-hsse.cloudfunctions.net/publicAPI/${this.context.user.terminal}/createOrUpdateUser`,
                                            {
                                              email: row.original.email,
                                              passport: row.original.passport,
                                              reset: true,
                                              passwordUpdated: false
                                            }
                                        )
                                        .then(res => {
                                          console.log(res)
                                          this.setState({
                                            alert: (
                                              <SweetAlert
                                                success
                                                style={{
                                                  display: 'block',
                                                  marginTop: '-200px'
                                                }}
                                                title='Email sent successfully!'
                                                onConfirm={() =>
                                                  this.setState({
                                                    alert: null
                                                  })}
                                                onCancel={() =>
                                                  this.setState({
                                                    alert: null
                                                  })}
                                                confirmBtnBsStyle='success'
                                              />
                                            )
                                          })
                                        })
                                        .catch(e => {
                                          console.log(e)
                                          this.setState({
                                            alert: (
                                              <SweetAlert
                                                danger
                                                style={{
                                                  display: 'block',
                                                  marginTop: '-200px'
                                                }}
                                                title='Sending email failed!'
                                                onConfirm={() =>
                                                  this.setState({
                                                    alert: null
                                                  })}
                                                onCancel={() =>
                                                  this.setState({
                                                    alert: null
                                                  })}
                                                confirmBtnBsStyle='danger'
                                              >
                                                  Please try again
                                              </SweetAlert>
                                            )
                                          })
                                        })
                                    }}
                                    simple
                                  >
                                    <i
                                      style={{
                                        color: '#FD642B',
                                        marginRight: '0.5rem'
                                      }}
                                      className='fas fa-redo'
                                    />
                                    {/* Reset Password */}
                                  </Button>
                                )
                              }
                            },
                            {
                              Header: 'Delete',
                              style: {
                                textAlign: 'right'
                              },
                              filterable: false,
                              sortable: false,
                              Cell: row => {
                                return (
                                  <Button
                                    onClick={() => {
                                      this.deleteUser(row.original)
                                    }}
                                    bsSize='xs'
                                    simple
                                  >
                                    {this.state.deletingUser ===
                                      row.original.passport ? (
                                        <i className='fa fa-spin fa-spinner' />
                                      ) : (
                                        <i className='fa fa-close text-danger' />
                                      )}
                                  </Button>
                                )
                              }
                            }
                          ]
                          : [
                            {
                              Header: 'Created At',
                              accessor: 'createdAt',
                              width: 130,
                              Cell: ({ row }) => {
                                const date = moment(row.createdAt).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                                return date
                              },
                              getProps: (state, rowInfo, column) => {
                                return {
                                  style: {
                                    fontFamily: 'Monospace',
                                    letterSpacing: '-1px'
                                  }
                                }
                              }
                            },
                            {
                              Header:
                                  this.isCustomPassportPlaceholder() ||
                                  'Passport',
                              accessor: 'passport',
                              width: 120,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'passport' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              },
                              Cell: ({ row }) => (
                                <Link to={'/user/' + row.passport}>
                                  <Badge>{row.passport}</Badge>
                                </Link>
                              )
                            },
                            {
                              Header: 'Name',
                              accessor: 'name',
                              width: 240,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'name' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              },
                              Cell: row => {
                                return (
                                  <Link
                                    style={{
                                      fontWeight: 'bold',
                                      color: '#444'
                                    }}
                                    to={'/user/' + row.original.passport}
                                  >
                                    {row.value}
                                  </Link>
                                )
                              }
                            },
                            {
                              Header: 'Type',
                              accessor: 'visitorType',
                              width: 160,
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'visitorType',
                                  { filter, onChange },
                                  this.context.terminal.userTypes.map(
                                    role => {
                                      return {
                                        label: ROLES[role].name,
                                        value: role
                                      }
                                    }
                                  )
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <span
                                      style={{
                                        fontFamily: 'ITCFranklin',
                                        color: ROLES[row.value].color
                                      }}
                                    >
                                      <img
                                        src={ROLES[row.value].image}
                                        width='22'
                                        style={{
                                          borderRadius: '50%',
                                          margin: '4px'
                                        }}
                                        alt='user'
                                      />
                                      {ROLES[row.value].name}
                                    </span>
                                  )
                                }
                              }
                            },
                            {
                              Header: 'Company',
                              accessor: 'company',
                              width: 140,
                              Filter: ({ filter, onChange }) => {
                                const defaultFilter = _.find(
                                  this.state.defaultFiltered,
                                  { id: 'company' }
                                )
                                let defaultValue = filter ? filter.value : ''
                                if (defaultFilter) {
                                  defaultValue = defaultFilter.value
                                }
                                return (
                                  <input
                                    onChange={event =>
                                      onChange(event.target.value)}
                                    value={defaultValue}
                                    style={{
                                      width: '100%'
                                    }}
                                  />
                                )
                              }
                            },
                            {
                              Header: 'Induction',
                              accessor: 'test',
                              width: 120,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'test',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <>
                                      <i
                                        style={{ color: '#87CB16' }}
                                        className='fa fa-check-circle'
                                      />
                                      <span style={{ color: '#87CB16' }}>Yes</span>
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <i
                                        style={{ color: '#FB404B' }}
                                        className='fa fa-times-circle'
                                      />
                                      <span style={{ color: '#FB404B' }}>No</span>
                                    </>
                                  )
                                }
                              }
                            },
                            {
                              Header: 'Penalty',
                              accessor: 'penalties',
                              width: 110,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'penalties',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.original.penalties) {
                                  const activePenalties = this.getActivePenalties(
                                    row.original.penalties
                                  )
                                  if (activePenalties.length > 0) {
                                    const max = activePenalties.reduce(
                                      function (prev, current) {
                                        return moment(prev.penaltyUntil) >
                                            moment(current.penaltyUntil)
                                          ? prev
                                          : current
                                      }
                                    )
                                    const edit = (
                                      <Tooltip>
                                        {max.permanent
                                          ? 'PERMANENT PENALTY'
                                          : 'Sanctioned up ' +
                                              moment(max.penaltyUntil).format(
                                                'DD/MM/YYYY'
                                              )}
                                      </Tooltip>
                                    )
                                    return (
                                      <OverlayTrigger
                                        placement='top'
                                        overlay={edit}
                                      >
                                        <Link
                                          style={{
                                            fontWeight: 'bold',
                                            color: '#444'
                                          }}
                                          to={
                                            '/user/' + row.original.passport
                                          }
                                        >
                                          <i
                                            style={{ color: '#EEAF30' }}
                                            className='fa fa-exclamation-circle'
                                          />
                                        </Link>
                                      </OverlayTrigger>
                                    )
                                  } else {
                                    return ''
                                  }
                                } else {
                                  return ''
                                }
                              }
                            },
                            {
                              Header: 'Active Session',
                              accessor: 'activeSession',
                              width: 140,
                              style: {
                                textAlign: 'center'
                              },
                              Filter: ({ filter, onChange }) =>
                                this.renderDropdownPlaceholder(
                                  'activeSession',
                                  { filter, onChange },
                                  dropDownOptions
                                ),
                              Cell: row => {
                                if (row.value) {
                                  return (
                                    <i
                                      style={{ color: '#87CB16' }}
                                      className='fa fa-circle'
                                    />
                                  )
                                } else {
                                  return (
                                    <i
                                      style={{ color: '#c4c4c4' }}
                                      className='fa fa-circle'
                                    />
                                  )
                                }
                              }
                            },
                            {
                              Header: 'Delete',
                              style: {
                                textAlign: 'right'
                              },
                              filterable: false,
                              sortable: false,
                              Cell: row => {
                                return (
                                  <Button
                                    onClick={() => {
                                      this.deleteUser(row.original)
                                    }}
                                    bsSize='xs'
                                    simple
                                  >
                                    {this.state.deletingUser ===
                                      row.original.passport ? (
                                        <i className='fa fa-spin fa-spinner' />
                                      ) : (
                                        <i className='fa fa-close text-danger' />
                                      )}
                                  </Button>
                                )
                              }
                            }
                          ]
                      }
                    />
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ float: 'right' }}
                simple
                bsStyle='info'
                onClick={async () => {
                  const res = await axios.post(
                    `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
                    {
                      filters: this.state.filters,
                      sort: this.state.sort
                    }
                  )

                  this.setState(
                    {
                      xlsData: res.data.results.map(item => {
                        return {
                          passport: item.passport,
                          activeSession: item.activeSession,
                          createdAt: item.createdAt,
                          language: item.language,
                          terminal: item.terminal,
                          updatedAt: item.updatedAt,
                          company: item.company,
                          name: item.name,
                          test: item.test,
                          visitorType: item.visitorType,
                          penalties: String(item.penalties?.length) || 'none',
                          inductionValidTo: moment(item.lastInductionTest)
                            .add(1, 'y')
                            .format('YYYY-MM-DDThh:mm:ssZ')
                        }
                      })
                    },
                    () => {
                      const dataTypes = {
                        passport: 'String',
                        activeSession: 'String',
                        createdAt: 'String',
                        language: 'String',
                        terminal: 'String',
                        updatedAt: 'String',
                        company: 'String',
                        name: 'String',
                        test: 'String',
                        visitorType: 'String',
                        penalties: 'String',
                        inductionValidTo: 'String'
                      }
                      const emitXmlHeader = () => {
                        let headerRow = '<ss:Row>\n'
                        for (const colName in dataTypes) {
                          headerRow += '  <ss:Cell>\n'
                          headerRow += '    <ss:Data ss:Type="String">'
                          headerRow += colName + '</ss:Data>\n'
                          headerRow += '  </ss:Cell>\n'
                        }
                        headerRow += '</ss:Row>\n'
                        return (
                          '<?xml version="1.0"?>\n' +
                          '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n' +
                          '<ss:Worksheet ss:Name="Sheet1">\n' +
                          '<ss:Table>\n\n' +
                          headerRow
                        )
                      }
                      const emitXmlFooter = () => {
                        return (
                          '\n</ss:Table>\n' +
                          '</ss:Worksheet>\n' +
                          '</ss:Workbook>\n'
                        )
                      }
                      const jsonToSsXml = jsonObject => {
                        let row
                        let col
                        let xml
                        const data =
                          typeof jsonObject !== 'object'
                            ? JSON.parse(jsonObject)
                            : jsonObject

                        xml = emitXmlHeader()

                        for (row = 0; row < data.length; row++) {
                          xml += '<ss:Row>\n'

                          for (col in data[row]) {
                            xml += '  <ss:Cell>\n'
                            xml +=
                              '    <ss:Data ss:Type="' + dataTypes[col] + '">'
                            xml += data[row][col] + '</ss:Data>\n'
                            xml += '  </ss:Cell>\n'
                          }

                          xml += '</ss:Row>\n'
                        }

                        xml += emitXmlFooter()
                        return xml
                      }
                      const url =
                        'data:text/xls;charset=utf-8,' +
                        encodeURIComponent(jsonToSsXml(this.state.xlsData))

                      const link = document.createElement('a')
                      link.style.display = 'none'
                      link.href = url
                      link.download = 'users.xls'
                      document.body.appendChild(link)
                      link.click()

                      console.log('Success!')
                    }
                  )
                }}
              >
                Download XLS
              </Button>
            </Col>
            <Col>
              <Button
                style={{ float: 'right' }}
                simple
                bsStyle='info'
                onClick={async () => {
                  const res = await axios.post(
                    `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
                    {
                      filters: this.state.filters,
                      sort: this.state.sort
                    }
                  )
                  this.setState(
                    {
                      csvData: res.data.results.map(item => {
                        return {
                          passport: item.passport,
                          activeSession: item.activeSession,
                          createdAt: item.createdAt,
                          language: item.language,
                          terminal: item.terminal,
                          updatedAt: item.updatedAt,
                          company: item.company,
                          name: item.name,
                          test: item.test,
                          visitorType: item.visitorType,
                          penalties: item.penalties
                        }
                      })
                    },
                    () => {
                      this.csvLink.current.link.click()
                    }
                  )
                }}
              >
                Download CSV
              </Button>
              <CSVLink
                separator=';'
                data={this.state.csvData}
                filename='users.csv'
                ref={this.csvLink}
                target='_blank'
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          backdrop='static'
          keyboard={false}
          show={this.state.modalNotes}
          onHide={() => this.setState({ modalNotes: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Visit notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalNotesText}</Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => this.setState({ modalNotes: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default Users
