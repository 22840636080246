import React, { Component } from 'react'
import { Nav, Navbar, NavDropdown, MenuItem } from 'react-bootstrap'
import firebase from 'auth/Auth'
import UserContext from 'context/Context'

class HeaderLinks extends Component {
  static contextType = UserContext
  render () {
    return (
      <div>
        <Nav pullRight>
          <Navbar.Header>
            <Navbar.Text style={{ paddingTop: '4px', display: 'flex' }}>
              Terminal:{' '}
              <img
                alt='flag'
                style={{ margin: '0 10px' }}
                src={`https://flagcdn.com/w40/${this.context.terminal.countryISO.toLowerCase()}.png`}
              />
              <b>{this.context.terminal.name}</b>
            </Navbar.Text>
            <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          </Navbar.Header>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className='fa fa-list' />
                <p className='hidden-md hidden-lg'>
                  Session
                  <b className='caret' />
                </p>
              </div>
            }
            noCaret
            id='basic-nav-dropdown-3'
            bsClass='dropdown-with-icons dropdown'
          >
            <MenuItem onClick={() => firebase.auth().signOut()} eventKey={4.5}>
              <div className='text-danger'>
                <i className='pe-7s-close-circle' /> Log out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    )
  }
}
export default HeaderLinks
