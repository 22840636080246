import React, { Component } from 'react'
import { Grid, Row, Col, Modal } from 'react-bootstrap'
import Card from 'components/Card/Card.jsx'
import axios from 'axios'
import UserContext from 'context/Context'
import UserCard from 'components/Card/UserCard.jsx'
import bgUser from 'assets/img/bg_user2.png'
import ReactTable from 'react-table'
import moment from 'moment'
import { ROLES } from 'config/roles'
import Button from 'components/CustomButton/CustomButton.jsx'
import firebase from 'auth/Auth.jsx'

const REP = [
  'Verbal warning and data collection with acknowledgment of receipt. On-site refresher training.',
  'Suspension for 1 day.Refresh of induction.',
  'Suspension for 5 days. Notice to the representative of the company and refreshment of the induction.',
  'Suspension for 15 days. Meeting with the representative of the company and refreshment of the induction.',
  'Denial of permanent entry for the offender.'
]

const PUSH_PENALTY = {
  es: [
    'Verbal warning and data collection with acknowledgment of receipt. On-site refresher training.',
    'Suspension for 1 day.Refresh of induction.',
    'Suspension for 5 days. Notice to the representative of the company and refreshment of the induction.',
    'Suspension for 15 days. Meeting with the representative of the company and refreshment of the induction.',
    'Denial of permanent entry for the offender.'
  ],
  en: [
    'Verbal warning and data collection with acknowledgment of receipt. On-site refresher training.',
    'Suspension for 1 day.Refresh of induction.',
    'Suspension for 5 days. Notice to the representative of the company and refreshment of the induction.',
    'Suspension for 15 days. Meeting with the representative of the company and refreshment of the induction.',
    'Denial of permanent entry for the offender.'
  ]
}

class User extends Component {
  static contextType = UserContext
  state = {
    loading: true,
    data: {},
    penalties: [],
    modalPenalty: false,
    selectedPenalty: null,
    loadingPenalty: false,
    deletingPenalty: false
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    this.setState({ loading: true })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
        {
          filters: {
            passport: this.props.match.params.passport
          }
        }
      )
      .then(res => {
        if (res.data.results.length === 0) {
          this.props.history.goBack()
        } else {
          this.setState({
            data: res.data.results[0],
            penalties: res.data.results[0].penalties,
            loading: false
          })
        }
      })
  }

  applyPenalty = async () => {
    this.setState({ loadingPenalty: true })
    await axios.post(
      `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/penalty`,
      {
        passport: this.props.match.params.passport,
        data: this.state.selectedPenalty
      }
    )

    const title =
      this.state.data.language === 'es'
        ? '⚠️ Has sido sancionado'
        : '⚠️ You have been sanctioned'
    const body =
      PUSH_PENALTY[this.state.data.language || 'es'][
        this.state.selectedPenalty.repeat - 1
      ]

    if (
      this.state.data.activeSession &&
      this.state.data.activeSession === true
    ) {
      await axios.post(
        'https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/push',
        {
          data: {
            to: this.state.data.pushId,
            title: title,
            body: body,
            sound: 'default',
            priority: 'high',
            data: JSON.stringify({ title: title, body: body })
          }
        }
      )
    }

    await firebase
      .firestore()
      .collection('terminals')
      .doc(this.context.user.terminal)
      .collection('notifications')
      .add({
        createdAt: new Date(),
        titleES: '⚠️ You have been sanctioned',
        bodyES: PUSH_PENALTY.es[this.state.selectedPenalty.repeat - 1],
        titleEN: '⚠️ You have been sanctioned',
        bodyEN: PUSH_PENALTY.en[this.state.selectedPenalty.repeat - 1],
        user: this.state.data.passport,
        receivers: 1
      })

    this.setState(
      {
        loadingPenalty: false,
        modalPenalty: false
      },
      () => this.loadData()
    )
  }

  deletePenalty = (passport, id) => {
    this.setState({ deletingPenalty: id })
    axios
      .post(
        `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/deletePenalty`,
        {
          passport: passport,
          id: id
        }
      )
      .then(res => {
        this.setState(
          {
            deletingPenalty: false
          },
          () => this.loadData()
        )
      })
  }

  render () {
    return (
      <div className='main-content'>
        {console.log(this.state.data)}
        <Grid fluid>
          <Row>
            <Col md={4}>
              <UserCard
                bgImage={bgUser}
                avatar={
                  ROLES[this.state.data.visitorType]?.image ||
                  require('assets/img/default-avatar.png')
                }
                name={this.state.data.name || ''}
                userName={this.state.data.passport || ''}
                description={
                  this.state.loading ? (
                    <div style={{ height: '305px' }}>
                      <div className='lds-ripple' style={{ marginTop: '80px' }}>
                        <div style={{ border: '4px solid #245a6f' }} />
                        <div />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span>
                        <p>{this.state.data.company || ''}</p>
                      </span>
                      <h6>{ROLES[this.state.data.visitorType]?.name}</h6>
                      <br />
                      <span>
                        Induction{' '}
                        {this.state.data.test ? (
                          <i
                            style={{ color: '#87CB16' }}
                            className='fa fa-check-circle'
                          />
                        ) : (
                          <i
                            style={{ color: '#FB404B' }}
                            className='fa fa-times-circle'
                          />
                        )}
                      </span>
                      <br />
                      <span>
                        Active app session{' '}
                        {this.state.data.activeSession ? (
                          <i
                            style={{ color: '#87CB16' }}
                            className='fa fa-circle'
                          />
                        ) : (
                          <i
                            style={{ color: '#c4c4c4' }}
                            className='fa fa-circle'
                          />
                        )}
                      </span>
                      <br />
                      {this.state.data.email ? (
                        <span>Email: {this.state.data.email}</span>
                      ) : null}

                      <br />
                      {this.state.data.extraData?.phoneNumber ? (
                        <span>
                          Phone Number: {this.state.data.extraData?.phoneNumber}
                        </span>
                      ) : null}

                      {/* <br />
                      <span>
                        <small>
                          {this.state.data.lastInductionTest && (
                            `Induction valid until: ${moment(this.state.data.lastInductionTest).add(1, 'y').format('YYYY-MM-DD hh:mm:ssZ')}`
                          )}

                        </small>
                      </span> */}
                      <br />
                      <span>
                        <small>
                          User registered:{' '}
                          {moment(this.state.data.createdAt).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </small>
                      </span>
                    </div>
                  )
                }
                socials={
                  <div style={{ padding: '4px 10px 18px 10px' }}>
                    {this.context.user.role === 'admin' && (
                      <Button
                        onClick={() =>
                          this.setState({
                            modalPenalty: true,
                            selectedPenalty: null
                          })
                        }
                        wd
                        bsStyle='warning'
                      >
                        <span className='btn-label'>
                          <i className='fa fa-warning' />
                        </span>{' '}
                        Sanction user
                      </Button>
                    )}
                  </div>
                }
              />
            </Col>
            <Col md={8}>
              <Card
                title='Penalties History'
                content={
                  <ReactTable
                    data={this.state.penalties}
                    loading={this.state.loading}
                    filterable
                    defaultSorted={[
                      {
                        id: 'createdAt',
                        desc: true
                      }
                    ]}
                    showPageSizeOptions={false}
                    defaultPageSize={9}
                    showPaginationBottom
                    className='-striped -highlight'
                    previousText='Previous'
                    nextText='Next'
                    loadingText='Loading...'
                    noDataText='No penalties'
                    pageText='Page'
                    ofText='de'
                    rowsText='penalties'
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'createdAt',
                        width: 180,
                        Cell: ({ row }) => {
                          const date = moment(row.createdAt).format(
                            'DD/MM/YYYY HH:mm'
                          )
                          return date
                        },
                        style: {
                          fontFamily: 'Monospace'
                        }
                      },
                      {
                        Header: 'Type',
                        accessor: 'type',
                        style: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Repetitiveness',
                        accessor: 'repeat'
                      },
                      {
                        Header: 'Points',
                        accessor: 'points'
                      },
                      {
                        Header: 'Delete',
                        style: {
                          textAlign: 'right'
                        },
                        filterable: false,
                        sortable: false,
                        Cell: row => {
                          return (
                            this.context.user.role === 'admin' && (
                              <Button
                                onClick={() => {
                                  this.deletePenalty(
                                    this.props.match.params.passport,
                                    row.original._id
                                  )
                                }}
                                bsSize='xs'
                                simple
                              >
                                {this.state.deletingPenalty ===
                                row.original._id ? (
                                  <i className='fa fa-spin fa-spinner' />
                                ) : (
                                  <i className='fa fa-close text-danger' />
                                )}
                              </Button>
                            )
                          )
                        }
                      }
                    ]}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          backdrop='static'
          keyboard={false}
          show={this.state.modalPenalty}
          onHide={() => this.setState({ modalPenalty: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>SANCTION USER</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col
                  style={{ padding: '15px', letterSpacing: '2px' }}
                  md={11}
                  mdOffset={1}
                >
                  <b>REPETITIVENESS</b> <i className='fa fa-arrow-right' />
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <span
                    style={{
                      writingMode: 'vertical-rl',
                      textOrientation: 'upright'
                    }}
                  >
                    <b>Type</b> <i className='fa fa-arrow-down' />
                  </span>
                </Col>
                <Col md={11}>
                  <table className='table table-striped table-condensed'>
                    <tbody>
                      <tr>
                        <td />
                        <td style={{ background: '#FFE4D7' }}>
                          <b>1</b>
                        </td>
                        <td style={{ background: '#FDCAAF' }}>
                          <b>2</b>
                        </td>
                        <td style={{ background: '#FBAF88' }}>
                          <b>3</b>
                        </td>
                        <td style={{ background: '#CF571E' }}>
                          <b style={{ color: '#ccc' }}>4</b>
                        </td>
                        <td style={{ background: '#893B14' }}>
                          <b style={{ color: '#ccc' }}>5</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: '#E1EFDB' }}>
                          <b>A</b>
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'A',
                                repeat: 1,
                                points: 5
                              }
                            })
                          }
                        >
                          5
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'A',
                                repeat: 2,
                                points: 10
                              }
                            })
                          }
                        >
                          10
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'A',
                                repeat: 3,
                                points: 15
                              }
                            })
                          }
                        >
                          15
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'A',
                                repeat: 4,
                                points: 30
                              }
                            })
                          }
                        >
                          30
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'A',
                                repeat: 5,
                                points: 60
                              }
                            })
                          }
                        >
                          60
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: '#C4DFB7' }}>
                          <b>B</b>
                        </td>
                        <td />
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'B',
                                repeat: 2,
                                points: 10
                              }
                            })
                          }
                        >
                          10
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'B',
                                repeat: 3,
                                points: 15
                              }
                            })
                          }
                        >
                          15
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'B',
                                repeat: 4,
                                points: 30
                              }
                            })
                          }
                        >
                          30
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'B',
                                repeat: 5,
                                points: 60
                              }
                            })
                          }
                        >
                          60
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: '#A6CF93' }}>
                          <b>C</b>
                        </td>
                        <td />
                        <td />
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'C',
                                repeat: 3,
                                points: 15
                              }
                            })
                          }
                        >
                          15
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'C',
                                repeat: 4,
                                points: 30
                              }
                            })
                          }
                        >
                          30
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'C',
                                repeat: 5,
                                points: 60
                              }
                            })
                          }
                        >
                          60
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: '#4F813D' }}>
                          <b style={{ color: '#ccc' }}>D</b>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'D',
                                repeat: 4,
                                points: 30
                              }
                            })
                          }
                        >
                          30
                        </td>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'D',
                                repeat: 5,
                                points: 60
                              }
                            })
                          }
                        >
                          60
                        </td>
                      </tr>
                      <tr>
                        <td style={{ background: '#345528' }}>
                          <b style={{ color: '#ccc' }}>E</b>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              selectedPenalty: {
                                type: 'E',
                                repeat: 5,
                                points: 60
                              }
                            })
                          }
                        >
                          60
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Grid>

            {this.state.selectedPenalty && (
              <>
                <h5>Description:</h5>
                <p>
                  A penalty of type <b> {this.state.selectedPenalty.type}</b>{' '}
                  will be applied with repetitiveness{' '}
                  <b>{this.state.selectedPenalty.repeat}</b> equivalent to{' '}
                  <b>{this.state.selectedPenalty.points}</b> points.
                </p>
                <p>
                  <b>Sanction:</b> {REP[this.state.selectedPenalty.repeat - 1]}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ modalPenalty: false })}>
              Close
            </Button>
            <Button
              disabled={
                this.state.loadingPenalty || this.state.selectedPenalty === null
              }
              bsStyle='warning'
              fill
              onClick={() => this.applyPenalty()}
            >
              <b>Sanction</b>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default User
