import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'
import apmLogo from 'assets/img/logo.png'

class PagesHeader extends Component {
  constructor (props) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
  }
  render () {
    return (
      <Navbar
        collapseOnSelect
        inverse
        className='navbar-primary navbar-transparent navbar-absolute'
      >
        <Navbar.Header>
          <Navbar.Brand>
            <img alt='APM Terminals' style={{ height: '100%' }} src={apmLogo} />
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
    )
  }
}

export default PagesHeader
