import React, { Component } from 'react'
import Select from 'react-select'

const themeStyles = theme => {
  return ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#eeeeee',
      primary50: '#b8b8b8',
      primary: '#585858'
    }
  })
}

const selectStyles = {
  container: (base, state) => {
    return {
      ...base,
      width: '100%',
      cursor: state.isDisabled ? 'not-allowed !important' : 'default',
      pointerEvents: 'all'
    }
  },
  control: (base, state) => {
    return {
      ...base,
      minHeight: 30,
      borderRadius: '4px',
      border: '1px solid #E3E3E3',
      fontSize: '12px',
      pointerEvents: state.isDisabled ? 'none' : 'all',
      backgroundColor: state.isDisabled ? '#E3E3E3' : 'initial'
    }
  },
  dropdownIndicator: base => ({
    ...base,
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4
  }),
  valueContainer: base => ({
    ...base,
    padding: '0px 6px'
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0
  }),
  multiValue: (base, state) => {
    return { ...base, backgroundColor: '#585858' }
  },
  multiValueLabel: (base, state) => {
    return { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
  },
  multiValueRemove: (base, state) => {
    return { ...base, fontWeight: 'bold', color: 'white' }
  },
  menu: (base, state) => {
    return { ...base, textAlign: 'left' }
  },
  option: (base, state) => {
    return { ...base, whiteSpace: 'pre-wrap' }
  }
}

class Dropdown extends Component {
  render () {
    return (
      <Select
        id='scene'
        styles={selectStyles}
        menuPortalTarget={document.body}
        theme={theme => themeStyles(theme)}
        {...this.props}
      />
    )
  }
}

export default Dropdown
