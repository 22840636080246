import Dashboard from 'views/Dashboard/Dashboard.jsx'
import Visits from 'views/Visits/Visits.jsx'
import Users from 'views/Users/Users.jsx'
import User from 'views/User/User.jsx'
import Notifications from 'views/Notifications/Notifications.jsx'
import Questions from 'views/Questions/Questions.jsx'
import StepVideoQuestions from 'views/Questions/StepVideoQuestions.jsx'
import Penalties from 'views/Penalties/Penalties.jsx'
import ManualInduction from 'views/ManualInduction/ManualInduction.jsx'
import Tracking from 'views/Tracking/Tracking.jsx'

var adminRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pe-7s-graph',
    component: Dashboard
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'pe-7s-users',
    component: Users
  },
  {
    path: '/visits',
    name: 'Visits',
    icon: 'pe-7s-id',
    component: Visits
  },
  {
    collapse: true,
    path: '/app',
    name: 'App',
    icon: 'pe-7s-phone',
    views: [
      {
        path: '/notifications',
        name: 'Notifications',
        mini: 'N',
        component: Notifications
      },
      {
        path: '/questions',
        name: 'Questions',
        mini: 'Q',
        component: Questions
      },
      {
        path: '/step-video-questions',
        name: 'Step Questions',
        mini: 'SQ',
        component: StepVideoQuestions
      }
    ]
  },
  {
    path: '/penalties',
    name: 'Penalties',
    icon: 'pe-7s-speaker',
    component: Penalties
  },
  {
    path: '/induction',
    name: 'Manual induction',
    icon: 'pe-7s-note2',
    component: ManualInduction
  },

  {
    path: '/user/:passport',
    name: 'Penalties',
    icon: 'pe-7s-speaker',
    component: User,
    visible: false
  },
  // {
  //   path: '/tracking',
  //   name: 'Tracking',
  //   icon: 'pe-7s-paper-plane',
  //   component: Tracking
  // },
  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard' }
]

var doorsRoutes = [
  {
    path: '/users',
    name: 'Users',
    icon: 'pe-7s-users',
    component: Users
  },
  {
    path: '/visits',
    name: 'Visits',
    icon: 'pe-7s-id',
    component: Visits
  },
  {
    path: '/user/:passport',
    name: 'Penalties',
    icon: 'pe-7s-speaker',
    component: User,
    visible: false
  },
  { redirect: true, path: '/', pathTo: '/users', name: 'Userds' }
]

export { adminRoutes, doorsRoutes }
