import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'
import NotificationSystem from 'react-notification-system'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import { adminRoutes, doorsRoutes } from 'routes/dashboard.jsx'
import { style } from 'variables/Variables.jsx'
import firebase from 'auth/Auth'
import bgImage from 'assets/img/login.jpg'
import UserContext from 'context/Context'

// var ps

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.handleNotificationClick = this.handleNotificationClick.bind(this)
    this.state = {
      user: null,
      terminal: null,
      dashboardRoutes: [],
      loading: true,
      _notificationSystem: null
    }
  }

  componentDidMount () {
    this.setState({ _notificationSystem: this.refs.notificationSystem })
    if (navigator.platform.indexOf('Win') > -1) {
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .collection('notifications')
          .orderBy('date', 'desc')
          .onSnapshot(snapshot => {
            this.setState({
              notifications: snapshot.docs.map(notification => {
                return {
                  uid: notification.id,
                  ...notification.data()
                }
              })
            })
          })
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              const role = doc.data().role
              var routes = []
              switch (role) {
                case 'admin':
                  routes = adminRoutes
                  break
                case 'doors':
                  routes = doorsRoutes
                  break
                default:
                  break
              }
              firebase
                .firestore()
                .collection('terminals')
                .doc(doc.data().terminal)
                .get()
                .then(terminalInfo => {
                  if (terminalInfo.exists) {
                    this.setState({
                      terminal: terminalInfo.data(),
                      user: { ...doc.data(), uid: user.id },
                      loading: false,
                      dashboardRoutes: routes
                    })
                  } else {
                    console.log('Document terminal error')
                  }
                })
            } else {
              console.log('Document error')
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })
      } else {
        this.props.history.push('/public/login-page')
        this.setState({
          currentUser: null,
          loading: false
        })
      }
    })
  }

  componentWillUnmount () {
    if (navigator.platform.indexOf('Win') > -1) {
      // ps.destroy()
    }
  }

  componentDidUpdate (e) {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        // ps.update()
      }, 350)
    }
    if (e.history.action === 'PUSH' && this.refs.mainPanel) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainPanel.scrollTop = 0
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === 'PUSH' &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
  }

  componentWillMount () {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open')
    }
  }

  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick (position) {
    var color = Math.floor(Math.random() * 4 + 1)
    var level
    switch (color) {
      case 1:
        level = 'success'
        break
      case 2:
        level = 'warning'
        break
      case 3:
        level = 'error'
        break
      case 4:
        level = 'info'
        break
      default:
        break
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify='icon' className='pe-7s-gift' />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
          every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    })
  }

  render () {
    if (this.state.loading === true) {
      return (
        <div className='wrapper wrapper-full-page'>
          <div className='full-page' data-color='blue' data-image={bgImage}>
            <div className='content'>
              <div className='lds-ripple'>
                <div />
                <div />
              </div>
            </div>
            <Footer transparent />
            <div
              className='full-page-background'
              style={{ backgroundImage: 'url(' + bgImage + ')' }}
            />
          </div>
        </div>
      )
    } else {
      return (
        <UserContext.Provider
          value={{
            user: this.state.user,
            terminal: this.state.terminal
          }}
        >
          <div className='wrapper'>
            <NotificationSystem ref='notificationSystem' style={style} />
            <Sidebar {...this.props} routes={this.state.dashboardRoutes} />
            <div
              className={
                'main-panel' +
                (this.props.location.pathname === '/maps/full-screen-maps'
                  ? ' main-panel-maps'
                  : '')
              }
              ref='mainPanel'
            >
              <Header routes={this.state.dashboardRoutes} {...this.props} />
              <Switch>
                {this.state.dashboardRoutes.map((prop, key) => {
                  if (prop.collapse) {
                    return prop.views.map((prop, key) => {
                      if (prop.name === 'Notifications') {
                        return (
                          <Route
                            path={prop.path}
                            key={key}
                            render={routeProps => (
                              <prop.component
                                {...routeProps}
                                handleClick={this.handleNotificationClick}
                              />
                            )}
                          />
                        )
                      } else {
                        return (
                          <Route
                            path={prop.path}
                            component={prop.component}
                            key={key}
                          />
                        )
                      }
                    })
                  } else {
                    if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      )
                    } else {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      )
                    }
                  }
                })}
              </Switch>
              <Footer fluid />
            </div>
          </div>
        </UserContext.Provider>
      )
    }
  }
}

export default Dashboard
