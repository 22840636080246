import React from 'react'

export const Dots = () => {
  return (
    <div className='lds-ellipsis'>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
