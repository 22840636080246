import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock
} from 'react-bootstrap'
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'
import firebase from 'auth/Auth.jsx'
import { Formik } from 'formik'
import * as Yup from 'yup'

class LoginPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cardHidden: true
    }
  }
  componentDidMount () {
    setTimeout(
      function () {
        this.setState({ cardHidden: false })
      }.bind(this),
      700
    )
  }
  render () {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={async (values, { setSubmitting }) => {
                const { email, password } = values
                try {
                  await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                  this.props.history.push('/')
                } catch (error) {
                  console.log('Login error')
                }
                setSubmitting(false)
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('No email provided.'),
                password: Yup.string()
                  .required('No password provided.')
                  .min(6, 'Password is too short - should be 6 chars minimum.')
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isValid,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props
                return (
                  <form onSubmit={handleSubmit}>
                    <Card
                      hidden={this.state.cardHidden}
                      content={
                        <React.Fragment>
                          <FormGroup validationState={errors.email && touched.email ? 'error' : ''}>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl
                              id='email'
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Email'
                              type='email' />
                            {errors.email && touched.email && (
                              <React.Fragment>
                                <FormControl.Feedback />
                                <HelpBlock>{errors.email}</HelpBlock>
                              </React.Fragment>
                            )}
                          </FormGroup>
                          <FormGroup validationState={errors.password && touched.password ? 'error' : ''}>
                            <ControlLabel>Password</ControlLabel>
                            <FormControl
                              id='password'
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder='Password'
                              type='password' />
                            {errors.password && touched.password && (
                              <React.Fragment>
                                <FormControl.Feedback />
                                <HelpBlock>{errors.password}</HelpBlock>
                              </React.Fragment>
                            )}
                          </FormGroup>
                        </React.Fragment>
                      }
                      legend={
                        <Button disabled={!dirty || isSubmitting || !isValid} type='submit' bsStyle='warning' fill wd>
                          LOGIN
                        </Button>
                      }
                      ftTextCenter
                    />
                  </form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default LoginPage
