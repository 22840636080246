import React, { Component } from 'react'

class Footer extends Component {
  render () {
    return (
      <footer
        className={
          'footer' +
          (this.props.transparent !== undefined ? ' footer-transparent' : '')
        }
      >
        <div
          className={
            'container' + (this.props.fluid !== undefined ? '-fluid' : '')
          }
        >
          <nav className='pull-left'>
            <ul>
              <li>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://www.apmterminals.com/en/legal-notice'
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://www.apmterminals.com/en/cookies'
                >
                  Cookies policy
                </a>
              </li>
              <li>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href='https://www.apmterminals.com/en/privacy-policy'
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </nav>
          <p className='copyright pull-right'>
            &copy; {1900 + new Date().getYear()}{' '}
            <a
              traget='_blank'
              rel='noopener noreferrer'
              href='https://www.apmterminals.com'
            >
              APM Terminals
            </a>
            . Developed by{' '}
            <a
              style={{ color: '#A4C95E' }}
              target='_blank'
              rel='noopener noreferrer'
              href='https://estudiocactus.com'
            >
              Estudio Cactus
            </a>
          </p>
        </div>
      </footer>
    )
  }
}
export default Footer
