import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
  apiKey: 'AIzaSyAIBuQ9GUWc5Lm7JbjiHiKknVkMI-OPvig',
  authDomain: 'apm-terminals-hsse.firebaseapp.com',
  databaseURL: 'https://apm-terminals-hsse.firebaseio.com',
  projectId: 'apm-terminals-hsse',
  storageBucket: 'apm-terminals-hsse.appspot.com',
  messagingSenderId: '1063696962209'
}
const firebase = fb.initializeApp(config)
firebase.firestore()

export default firebase
