import React, { Component } from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import ChartistGraph from 'react-chartist'
import Card from 'components/Card/Card.jsx'
import StatsCard from 'components/Card/StatsCard.jsx'
import moment from 'moment'
import axios from 'axios'
import UserContext from 'context/Context'
import { ROLES } from 'config/roles'
import { Dots } from 'components/Loader/Dots'

const _ = require('underscore')

const optionsBar = {
  seriesBarDistance: 10,
  axisX: {
    showGrid: false
  },
  height: '245px'
}

const responsiveBar = [
  [
    'screen and (max-width: 640px)',
    {
      seriesBarDistance: 5,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0]
        }
      }
    }
  ]
]

class Dashboard extends Component {
  static contextType = UserContext;
  state = {
    totalsByRole: {},
    dataPie: {
      labels: [],
      series: []
    },
    loadingPie: false,
    dataBar: {
      labels: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
      ],
      series: []
    },
    loadingBar: false,
    visitsToday: 0,
    loadingVisitsToday: false,
    visitsMonth: 0,
    loadingVisitsMonth: false,
    penalties: 0,
    loadingPenalties: false,
    noInduction: 0,
    loadingNoInduction: false
  }

  async componentDidMount () {
    this.setState({
      loadingPie: true,
      loadingBar: true,
      loadingVisitsToday: true,
      loadingVisitsMonth: true,
      loadingPenalties: true,
      loadingNoInduction: true
    })

    const totals = {}
    for (const userType of this.context.terminal.userTypes) {
      const result = await axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`, {
        count: true,
        filters: { visitorType: userType }
      })
      totals[userType] = result.data.results
    }
    this.setState({ totalsByRole: totals })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visitsToday`)
      .then(res => {
        this.setState({
          visitsToday: res.data.results,
          loadingVisitsToday: false
        })
      })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visitsMonth`)
      .then(res => {
        this.setState({
          visitsMonth: res.data.results,
          loadingVisitsMonth: false
        })
      })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`, {
      count: true,
      filters: { 'penalties.0': { $exists: true } }
    })
      .then(res => {
        this.setState({
          penalties: res.data.results,
          loadingPenalties: false
        })
      })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`, {
      count: true,
      filters: { test: { $ne: true } }
    })
      .then(res => {
        this.setState({
          noInduction: res.data.results,
          loadingNoInduction: false
        })
      })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`, {
      count: true
    })
      .then(total => {
        axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`, {
          aggregate: [
            {
              $group: {
                _id: { $toLower: '$contactDepartment' },
                count: { $sum: 1 }
              }
            },
            {
              $group: {
                _id: null,
                counts: {
                  $push: {
                    k: '$_id',
                    v: '$count'
                  }
                }
              }
            },
            {
              $replaceRoot: {
                newRoot: { $arrayToObject: '$counts' }
              }
            }
          ]
        })
          .then(res => {
            const visits = res.data.results[0] || {}
            this.setState({
              loadingPie: false,
              dataPie: {
                labels: [
                  visits.administration ? parseFloat((visits.administration / total.data.results) * 100).toFixed(2) + '%' : '',
                  visits.maintenance ? parseFloat((visits.maintenance / total.data.results) * 100).toFixed(2) + '%' : '',
                  visits.operations ? parseFloat((visits.operations / total.data.results) * 100).toFixed(2) + '%' : ''
                ],
                series: [
                  visits.administration / total.data.results || 0,
                  visits.maintenance / total.data.results || 0,
                  visits.operations / total.data.results || 0
                ]
              }
            })
          })
      })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/visits`, {
      aggregate: [
        {
          $project: {
            _id: 0,
            year: { $year: '$createdAt' },
            month: { $month: '$createdAt' },
            reason: '$reason',
            visits: 1
          }
        },
        {
          $match: {
            year: 2020
          }
        },
        {
          $group: {
            _id: {
              month: '$month',
              year: '$year',
              reason: '$reason'
            },
            visits: { $sum: 1 }
          }
        },
        {
          $project: {
            _id: 0,
            month: '$_id.month',
            reason: '$_id.reason',
            visits: 1
          }
        }
      ]
    })
      .then(res => {
        const reasonGroup = _.groupBy(res.data.results, 'reason')

        let visit = []
        if (reasonGroup.visit) {
          const monthGroup = _.groupBy(reasonGroup.visit, 'month')
          for (let i = 1; i <= 12; i++) {
            if (monthGroup[i]) {
              visit.push(monthGroup[i][0].visits)
            } else {
              visit.push(0)
            }
          }
        } else {
          visit = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        let externalWorks = []
        if (reasonGroup.externalWorks) {
          const monthGroup = _.groupBy(reasonGroup.externalWorks, 'month')
          for (let i = 1; i <= 12; i++) {
            if (monthGroup[i]) {
              externalWorks.push(monthGroup[i][0].visits)
            } else {
              externalWorks.push(0)
            }
          }
        } else {
          externalWorks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        let shipOperations = []
        if (reasonGroup.shipOperations) {
          const monthGroup = _.groupBy(reasonGroup.shipOperations, 'month')
          for (let i = 1; i <= 12; i++) {
            if (monthGroup[i]) {
              shipOperations.push(monthGroup[i][0].visits)
            } else {
              shipOperations.push(0)
            }
          }
        } else {
          shipOperations = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        let reception = []
        if (reasonGroup.reception) {
          const monthGroup = _.groupBy(reasonGroup.reception, 'month')
          for (let i = 1; i <= 12; i++) {
            if (monthGroup[i]) {
              reception.push(monthGroup[i][0].visits)
            } else {
              reception.push(0)
            }
          }
        } else {
          reception = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }

        this.setState({
          loadingBar: false,
          dataBar: {
            ...this.state.dataBar,
            series: [visit, externalWorks, shipOperations, reception]
          }
        })
      })
  }

  render () {
    return (
      <div className='main-content'>
        <Grid fluid>
          <Row>
            {this.context.terminal.userTypes.map(userType => {
              return (
                <Col
                  key={userType}
                  lg={this.context.terminal.userTypes.length === 1 ? 6 : 12 / this.context.terminal.userTypes.length}
                  sm={12}
                >
                  <StatsCard
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      this.props.history.push('/users?filters=' + JSON.stringify({
                        visitorType: userType
                      }) + '&defaultFiltered=' + JSON.stringify([{
                        id: 'visitorType', value: userType
                      }]))}
                    bigIcon={(<img alt={userType} style={{ width: '60px', borderRadius: '50%' }} src={ROLES[userType].image} />)}
                    statsText={ROLES[userType].name}
                    statsValue={this.state.totalsByRole[userType] === undefined ? <Dots /> : this.state.totalsByRole[userType]}
                    statsIcon={<i className='pe-7s-users' />}
                    statsIconText={'Registered ' + ROLES[userType].name}
                  />
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-id text-info' />}
                statsText='Visits today'
                statsValue={this.state.loadingVisitsToday ? '...' : this.state.visitsToday}
                statsIcon={<i className='fa fa-calendar-o' />}
                statsIconText='Visitors today'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className='pe-7s-id text-success' />}
                statsText='Visits month'
                statsValue={this.state.loadingVisitsMonth ? '...' : this.state.visitsMonth}
                statsIcon={<i className='fa fa-calendar-o' />}
                statsIconText='Visitors this month'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.props.history.push('/penalties')}
                bigIcon={<i className='pe-7s-speaker text-danger' />}
                statsText='Penalties'
                statsValue={this.state.loadingPenalties ? '...' : this.state.penalties}
                statsIcon={<i className='pe-7s-users' />}
                statsIconText='Users sanctioned'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  this.props.history.push('/users?filters=' + JSON.stringify({
                    test: false
                  }) + '&defaultFiltered=' + JSON.stringify([{
                    id: 'test', value: false
                  }]))}
                bigIcon={<i className='pe-7s-attention text-warning' />}
                statsText='No induction'
                statsValue={this.state.loadingNoInduction ? '...' : this.state.noInduction}
                statsIcon={<i className='pe-7s-users' />}
                statsIconText='Users without induction'
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card
                title='Contact department'
                category='Visitor contact department'
                content={
                  this.state.loadingPie ? (
                    <div style={{ height: '305px' }}>
                      <div className='lds-ripple' style={{ marginTop: '80px' }}>
                        <div style={{ border: '4px solid #245a6f' }} />
                        <div />
                      </div>

                    </div>
                  ) : (
                    <ChartistGraph data={this.state.dataPie} type='Pie' />
                )
                }
                legend={
                  <div>
                    <br />
                    <i className='fa fa-circle' style={{ color: '#3CB6CE' }} /> Administration/Management <br />
                    <i className='fa fa-circle' style={{ color: '#004165' }} /> Maintenance <br />
                    <i className='fa fa-circle' style={{ color: '#FF6319' }} /> Operations <br />
                  </div>
                }
                stats={
                  <div>
                    <i className='fa fa-history' /> Updated data {moment().format('DD/MM/YYYY - HH:mm')}
                  </div>
                }
              />
            </Col>
            <Col md={8}>
              <Card
                title='The reason of the visit'
                category='Reason for the visit to the terminal'
                content={
                  this.state.loadingBar ? (
                    <div style={{ height: '305px' }}>
                      <br />
                      <div className='lds-ripple' style={{ marginTop: '80px' }}>
                        <div style={{ border: '4px solid #245a6f' }} />
                        <div />
                      </div>
                      <br />
                    </div>
                  ) : (
                    <ChartistGraph
                      data={this.state.dataBar}
                      type='Bar'
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                )
                }
                legend={
                  <div>
                    <i className='fa fa-circle' style={{ color: '#3CB6CE' }} /> Visit <br />
                    <i className='fa fa-circle' style={{ color: '#004165' }} /> Reception/Delivery <br />
                    <i className='fa fa-circle' style={{ color: '#FF6319' }} /> Extreme works <br />
                    <i className='fa fa-circle' style={{ color: '#EEAF30' }} /> Ship operations <br />
                  </div>
                }
                stats={
                  <div>
                    <i className='fa fa-history' /> Updated data {moment().format('DD/MM/YYYY - HH:mm')}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default Dashboard
