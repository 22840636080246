import React, { Component } from 'react'
import { Collapse } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'
import HeaderLinks from 'components/Header/HeaderLinks.jsx'
import image from 'assets/img/login.jpg'
import avatar from 'assets/img/default-avatar.png'
import avatarFemale from 'assets/img/default-avatar-female.png'
import logo from 'assets/img/logo_sidebar.png'
import UserContext from 'context/Context'

const bgImage = { backgroundImage: 'url(' + image + ')' }

// var ps

class Sidebar extends Component {
  static contextType = UserContext
  constructor (props) {
    super(props)
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components') !== '',
      openForms: this.activeRoute('/forms') !== '',
      openTables: this.activeRoute('/tables') !== '',
      openMaps: this.activeRoute('/maps') !== '',
      openPages: this.activeRoute('/public') !== '',
      isWindows: navigator.platform.indexOf('Win') > -1,
      width: window.innerWidth
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute (routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions () {
    this.setState({ width: window.innerWidth })
  }

  componentDidMount () {
    this.updateDimensions()
    // add event listener for windows resize
    window.addEventListener('resize', this.updateDimensions.bind(this))
    if (navigator.platform.indexOf('Win') > -1) {
    }
  }

  componentDidUpdate () {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        // ps.update()
      }, 350)
    }
  }

  componentWillUnmount () {
    if (navigator.platform.indexOf('Win') > -1) {
      // ps.destroy()
    }
  }

  render () {
    let avatarImage = avatar
    if (this.context.user.gender === 'female') {
      avatarImage = avatarFemale
    }
    return (
      <div
        className='sidebar'
        data-color='blue'
        data-image={image}
        style={{ overflowY: 'hidden' }}
      >
        <div className='sidebar-background' style={bgImage} />
        <div className='logo'>
          <div className='logo-img'>
            <img src={logo} alt='react-logo' />
          </div>
        </div>
        <div
          className='sidebar-wrapper'
          ref='sidebarWrapper'
          style={{ overflowX: 'hidden' }}
        >
          <div className='user'>
            <div className='photo'>
              <img src={avatarImage} alt='Avatar' />
            </div>
            <div className='info'>
              {/* eslint-disable-next-line */}
              <a
                href={null}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {this.context.user.name + ' ' + this.context.user.surname}
                </span>
              </a>
            </div>
          </div>
          <ul className='nav'>
            {this.state.width <= 992 ? <HeaderLinks /> : null}
            {this.props.routes.map((prop, key) => {
              if (prop.visible !== false) {
                var st = {}
                st[prop.state] = !this.state[prop.state]
                if (prop.collapse) {
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      {/* eslint-disable-next-line */}
                      <a href={null} onClick={() => this.setState(st)}>
                        <i className={prop.icon} />
                        <p>
                          {prop.name}
                          <b
                            className={
                              this.state[prop.state]
                                ? 'caret rotate-180'
                                : 'caret'
                            }
                          />
                        </p>
                      </a>
                      <Collapse in={this.state[prop.state]}>
                        <ul className='nav'>
                          {prop.views.map((prop, key) => {
                            return (
                              <li
                                className={this.activeRoute(prop.path)}
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  className='nav-link'
                                  activeClassName='active'
                                >
                                  <span className='sidebar-mini'>
                                    {prop.mini}
                                  </span>
                                  <span className='sidebar-normal'>
                                    {prop.name}
                                  </span>
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  )
                } else {
                  if (prop.redirect) {
                    return null
                  } else {
                    return (
                      <li className={this.activeRoute(prop.path)} key={key}>
                        <NavLink
                          to={prop.path}
                          className='nav-link'
                          activeClassName='active'
                        >
                          <i className={prop.icon} />
                          <p>{prop.name}</p>
                        </NavLink>
                      </li>
                    )
                  }
                }
              } else {
                return null
              }
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
