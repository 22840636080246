export const ROLES = {
  visitor: {
    name: 'Visitor',
    image: require('assets/img/visitor.jpg'),
    color: '#FC642B'
  },
  carrier: {
    name: 'Truck Driver',
    image: require('assets/img/carrier.jpg'),
    color: '#EBAC3F'
  },
  contractor: {
    name: 'Contractor',
    image: require('assets/img/contractor.jpg'),
    color: '#064266'
  },
  fruitTrucker: {
    name: 'Fruit Trucker',
    image: require('assets/img/fruitTrucker.jpg'),
    color: '#F94350'
  },
  containerTrucker: {
    name: 'Container Trucker',
    image: require('assets/img/containerTrucker.jpg'),
    color: '#A1D35A'
  },
  visitorPif: {
    name: 'Visitor PIF',
    image: require('assets/img/visitorPif.jpg'),
    color: '#43B5CD'
  },
  employee: {
    name: 'Employee',
    image: require('assets/img/employee.jpg'),
    color: '#FCE099'
  },
  shipService: {
    name: 'Ship Service',
    image: require('assets/img/shipService.jpg'),
    color: '#948D87'
  }
}
