import LoginPage from 'views/Public/LoginPage.jsx'

var publicRoutes = [
  {
    path: '/public/login-page',
    name: 'Login Page',
    mini: 'LP',
    component: LoginPage
  }
]

export default publicRoutes
