import React, { useState, useEffect, useContext } from 'react'

// Deck GL
import DeckGL from '@deck.gl/react'
import { IconLayer, BitmapLayer, TextLayer } from '@deck.gl/layers'
import { StaticMap } from 'react-map-gl'
import { SimpleMeshLayer } from '@deck.gl/mesh-layers'
import { OBJLoader } from '@loaders.gl/obj'
import { DracoLoader } from '@loaders.gl/draco'
import { PLYLoader } from '@loaders.gl/ply'
import { registerLoaders } from '@loaders.gl/core'

// Images
import terminalImg from '../../assets/img/terminal_no_water.png'
import mapMarker from '../../assets/img/Map_point.obj'
import mapModel from '../../assets/maps/portElizabeth/map.obj'
import mapTexture from '../../assets/maps/portElizabeth/base.jpg'
import mapTextureAlt from '../../assets/maps/portElizabeth/baseAlt.jpg'
import textureParams from '../../assets/maps/portElizabeth/map.mtl'

// Context
import UserContext from 'context/Context'

// Pubnub
import PubNub from 'pubnub'

// Regions
import regions from '../../config/regions'

registerLoaders(OBJLoader)
registerLoaders(DracoLoader)

const pubNubClient = new PubNub({
  publishKey: 'pub-c-033b0bfc-4f0b-4705-8ec5-1aa8b97f9240',
  subscribeKey: 'sub-c-5d10cb1c-b882-11eb-b22a-ee35b8e5702f'
})

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiaGFtZGV2MjIwMyIsImEiOiJja2lpcjVrdDMyN3lsMnlvNTJzOGR1cTUzIn0.Huc6ov4ndvN1SdN1g8YRpA'

// const leftBottom = [-74.148528, 40.656043]
// const leftTop = [-74.167697, 40.664097]
// const rightTop = [-74.160222, 40.674179]
// const rightBottom = [-74.140971, 40.667]

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: true }
}

const Map = props => {
  const userContext = useContext(UserContext)
  const [isSubscribed, setIsSubscribed] = useState(null)

  const [viewport, setViewport] = useState(
    regions[regions.length - 1]
  )
  const [locations, setLocations] = useState([])

  useEffect(() => {
    if (isSubscribed) {
      pubNubClient.addListener({
        message: function (m) {
          console.log('Message received', m)
          console.log('test', locations.map(location => location.name))
          const allLocationNames = JSON.parse(JSON.stringify(locations)).map(location => location.name)
          if (!allLocationNames.includes(m.channel)) {
            locations.push({
              name: m.channel,
              ...m.message
            })
            setLocations([...locations])
          }
        }
      })
    }
  }, [isSubscribed])

  useEffect(() => {
    pubNubClient.subscribe({
      channels: ['terminal.*']
    })
    setIsSubscribed(true)
  }, [])

  useEffect(() => {
    console.log(locations)
  }, [locations])

  return (
    <DeckGL
      initialViewState={viewport}
      controller
      style={{
        position: 'relative',
        height: '100%',
        width: '100%'
      }}
      useDevicePixels={false}
      onViewportChange={viewport => setViewport(viewport)}
      getTooltip={({ object }) => {
        return object
          ? {
            html: `<div><h1>${object.name}</h1></div>`
          }
          : null
      }}
    >
      <StaticMap
        {...viewport}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle='mapbox://styles/mapbox/outdoors-v11'
        onViewportChange={viewport => setViewport(viewport)}
      />
      {/* <BitmapLayer
        id='terminal'
        bounds={[leftBottom, leftTop, rightTop, rightBottom]}
        image={terminalImg}
      /> */}
      <SimpleMeshLayer
        loader={OBJLoader}
        data={[
          {
            identifier: 'portElizabeth',
            lat: 40.656609,
            long: -74.165224,
            radius: 2000,

            zoom: 13,
            pitch: 0,
            bearing: 0
          }
        ]}
        texture={mapTextureAlt}
        mesh={mapModel}
        sizeScale={0.0099}
        getPosition={d => {
          return [d.long, d.lat]
        }}
        getOrientation={d => [0, 243, 90]}
      />

      {locations.map((location, index) => {
        console.log('The location', location)
        return (
          <IconLayer
            key={index}
            id={location.name}
            data={[location]}
            iconAtlas='https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png'
            iconMapping={ICON_MAPPING}
            getIcon={d => 'marker'}
            sizeScale={15}
            getPosition={d => [d.long, d.lat]}
            getSize={d => 5}
            getColor={d => [255, 0, 0]}
          />
        )
      })}
    </DeckGL>
  )
}

export default Map
