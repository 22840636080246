export const LANGUAGES = {
  EN: {
    name: 'English',
    flag: 'gb'
  },
  ES: {
    name: 'Español',
    flag: 'es'
  },
  IT: {
    name: 'Italiano',
    flag: 'it'
  },
  SE: {
    name: 'Svenska',
    flag: 'se'
  }
}
