import React from 'react'

import Map from 'components/MapView/Map.jsx'

const Tracking = props => {
  return (
    <div style={{ height: '100%' }}>
      <Map />
    </div>
  )
}

export default Tracking
