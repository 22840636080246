import React, { Component } from 'react'

export class StatsCard extends Component {
  render () {
    const {
      statsIcon,
      statsIconText,
      statsText,
      statsValue,
      bigIcon,
      ...rest
    } = this.props
    return (
      <div {...rest} className='card card-stats'>
        <div className='content'>
          <div className='row'>
            <div className='col-xs-5'>
              <div className='icon-big text-center icon-warning'>{bigIcon}</div>
            </div>
            <div className='col-xs-7'>
              <div className='numbers'>
                <p>{statsText}</p>
                {statsValue}
              </div>
            </div>
          </div>
        </div>
        <div className='footer'>
          <hr />
          <div className='stats'>
            {statsIcon} {statsIconText}
          </div>
        </div>
      </div>
    )
  }
}

export default StatsCard
