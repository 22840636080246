import React, { Component } from 'react'

// Firebase
import firebase from 'auth/Auth.jsx'

// Context
import UserContext from 'context/Context'

// React Bootstrap
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Badge
} from 'react-bootstrap'

// Core Components
import Dropdown from 'components/Dropdown/Dropdown.jsx'
import Card from 'components/Card/Card.jsx'
import Button from 'components/CustomButton/CustomButton.jsx'

// Formik
import * as Yup from 'yup'
import { Formik } from 'formik'

// Config
import { ROLES } from 'config/roles'

// Utils
import axios from 'axios'
import moment from 'moment'
import ReactTable from 'react-table'
import SweetAlert from 'react-bootstrap-sweetalert'
import pushPreview from 'assets/img/push_preview.png'
import { Link } from 'react-router-dom'
import { LANGUAGES } from 'config/languages'
const _ = require('underscore')

class Notifications extends Component {
  static contextType = UserContext

  state = {
    selectedLan: 'EN',
    alert: null,
    data: [],
    loading: true,
    activeUsers: 0,
    roleReceivers: { label: 'All', value: 'all' }
  }

  componentDidMount = async () => {
    firebase
      .firestore()
      .collection('terminals')
      .doc(this.context.user.terminal)
      .collection('notifications')
      .onSnapshot(snapshot => {
        this.setState({
          loading: false,
          data: snapshot.docs.map(not => not.data())
        })
      })

    this.getCountUsers()
  }

  getCountUsers = async () => {
    const activeUsers = await axios.post(
      `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
      {
        count: true,
        filters:
          this.state.roleReceivers.value === 'all'
            ? { pushId: { $ne: '' } }
            : {
                pushId: { $ne: '' },
                visitorType: this.state.roleReceivers.value
              }
      }
    )

    this.setState({ activeUsers: activeUsers.data.results })
  }

  successAlert = msg => {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-200px' }}
          title='Sent sucessful'
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle='info'
        >
          {msg}
        </SweetAlert>
      )
    })
  }

  chunkArray = (array, size) => {
    var index = 0
    var arrayLength = array.length
    var tempArray = []

    for (index = 0; index < arrayLength; index += size) {
      const myChunk = array.slice(index, index + size)
      tempArray.push(myChunk)
    }

    return tempArray
  }

  render () {
    return (
      <div className='main-content'>
        {this.state.alert}
        <Formik
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const { titleEN, bodyEN } = values
            const users = await axios.post(
              `https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`,
              {
                filters:
                  this.state.roleReceivers.value === 'all'
                    ? { pushId: { $ne: '' } }
                    : {
                        pushId: { $ne: '' },
                        visitorType: this.state.roleReceivers.value
                      }
              }
            )

            const data = []
            if (users) {
              users.data.results.forEach(user => {
                let title = titleEN
                let body = bodyEN
                if (user.language) {
                  title =
                    values['title' + user.language.toUpperCase()] || titleEN
                  body = values['body' + user.language.toUpperCase()] || bodyEN
                }
                if (user.pushId) {
                  data.push({
                    to: user.pushId.data,
                    title,
                    body,
                    sound: 'default',
                    priority: 'high',
                    data: JSON.stringify({ title: title, body: body })
                  })
                }
              })
            }

            const splitedNotifications = this.chunkArray(data, 100)
            const promises = []

            for (const group of splitedNotifications) {
              const axiosPromise = axios.post(
                'https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/push',
                {
                  data: group
                }
              )
              promises.push(axiosPromise)
            }

            Promise.all(promises).then(promisesResult => {
              let receivers = 0
              promisesResult.forEach(res => {
                const result = _.groupBy(res.data.data.data, 'status')
                receivers += result?.ok?.length
              })

              this.successAlert(
                'Notification successfully sent to ' + receivers + ' users.'
              )

              firebase
                .firestore()
                .collection('terminals')
                .doc(this.context.user.terminal)
                .collection('notifications')
                .add({
                  createdAt: new Date(),
                  ...values,
                  roleReceivers: this.state.roleReceivers.value,
                  user: 'all',
                  receivers: receivers
                })

              setSubmitting(false)
              resetForm()
            })
          }}
          validationSchema={Yup.object().shape({
            titleEN: Yup.string().required('Mandatory title.'),
            bodyEN: Yup.string().required('Mandatory message.')
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isValid,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props
            return (
              <Grid fluid>
                <Row style={{ display: 'flex', paddingBottom: '40px' }}>
                  <Col md={5} lg={5} xl={4} sm={12}>
                    <Card
                      height100
                      title='Preview'
                      content={
                        <div style={{ position: 'relative' }}>
                          <img
                            alt=''
                            style={{
                              position: 'absolute',
                              top: '33%',
                              left: '14%',
                              width: '20px',
                              borderRadius: '4px'
                            }}
                            src={require('assets/img/icon-default.png')}
                          />
                          <small
                            className='text-muted'
                            style={{
                              position: 'absolute',
                              top: '33%',
                              left: '10%',
                              fontSize: '14px',
                              marginLeft: '50px',
                              borderRadius: '4px',
                              width: '68%',
                              fontWeight: '600'
                            }}
                          >
                            <span style={{ textTransform: 'uppercase' }}>
                              {this.context.user.terminalName}
                            </span>{' '}
                            HSSE
                          </small>
                          <small
                            className='text-muted'
                            style={{
                              position: 'absolute',
                              top: '42%',
                              left: '2%',
                              fontSize: '14px',
                              marginLeft: '50px',
                              borderRadius: '4px',
                              width: '68%',
                              fontWeight: '400'
                            }}
                          >
                            {Object.keys(LANGUAGES).map(lan => {
                              if (values && this.state.selectedLan === lan) {
                                return values['title' + lan]
                              } else {
                                return null
                              }
                            })}
                            <br />
                            {Object.keys(LANGUAGES).map(lan => {
                              if (values && this.state.selectedLan === lan) {
                                return values['body' + lan]
                              } else {
                                return null
                              }
                            })}
                          </small>
                          <img alt='' width='100%' src={pushPreview} />
                        </div>
                      }
                    />
                  </Col>
                  <Col md={7} lg={7} xl={8} sm={12}>
                    <Card
                      height100
                      title='Sending Info'
                      content={
                        <form onSubmit={handleSubmit}>
                          <>
                            <FormGroup
                              validationState={
                                errors.title && touched.title ? 'error' : ''
                              }
                            >
                              <ControlLabel style={{ width: '100%' }}>
                                <div>
                                  Title
                                  <div style={{ float: 'right' }}>
                                    {Object.keys(LANGUAGES).map(lan => {
                                      return (
                                        <img
                                          key={lan}
                                          alt='flag'
                                          onClick={() =>
                                            this.setState({ selectedLan: lan })
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            opacity:
                                              this.state.selectedLan === lan
                                                ? 1
                                                : 0.2,
                                            margin: '0 10px'
                                          }}
                                          src={`https://flagcdn.com/w40/${LANGUAGES[
                                            lan
                                          ].flag.toLowerCase()}.png`}
                                        />
                                      )
                                    })}
                                  </div>
                                </div>
                              </ControlLabel>
                              {Object.keys(LANGUAGES).map(lan => {
                                if (this.state.selectedLan === lan) {
                                  return (
                                    <>
                                      <FormControl
                                        id={'title' + lan}
                                        value={values && values['title' + lan]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Title in ${LANGUAGES[lan].name}...`}
                                      />
                                      {errors['title' + lan] &&
                                        touched['title' + lan] && (
                                          <>
                                            <FormControl.Feedback />
                                            <HelpBlock>
                                              {errors['title' + lan]}
                                            </HelpBlock>
                                          </>
                                        )}
                                    </>
                                  )
                                } else {
                                  return null
                                }
                              })}
                            </FormGroup>
                            <FormGroup
                              validationState={
                                errors.body && touched.body ? 'error' : ''
                              }
                            >
                              <ControlLabel style={{ width: '100%' }}>
                                <div>
                                  Message
                                  <div style={{ float: 'right' }}>
                                    {Object.keys(LANGUAGES).map(lan => {
                                      return (
                                        <img
                                          key={lan}
                                          alt='flag'
                                          onClick={() =>
                                            this.setState({ selectedLan: lan })
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            opacity:
                                              this.state.selectedLan === lan
                                                ? 1
                                                : 0.2,
                                            margin: '0 10px'
                                          }}
                                          src={`https://flagcdn.com/w40/${LANGUAGES[
                                            lan
                                          ].flag.toLowerCase()}.png`}
                                        />
                                      )
                                    })}
                                  </div>
                                </div>
                              </ControlLabel>
                              {Object.keys(LANGUAGES).map(lan => {
                                if (this.state.selectedLan === lan) {
                                  return (
                                    <>
                                      <FormControl
                                        id={'body' + lan}
                                        value={values && values['body' + lan]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Message in ${LANGUAGES[lan].name}...`}
                                        rows='3'
                                        componentClass='textarea'
                                      />
                                      {errors['body' + lan] &&
                                        touched['body' + lan] && (
                                          <>
                                            <FormControl.Feedback />
                                            <HelpBlock>
                                              {errors['body' + lan]}
                                            </HelpBlock>
                                          </>
                                        )}
                                    </>
                                  )
                                } else {
                                  return null
                                }
                              })}
                              <br />
                              <label>Target:</label>
                              <Dropdown
                                value={this.state.roleReceivers}
                                onChange={value =>
                                  this.setState({ roleReceivers: value }, () =>
                                    this.getCountUsers()
                                  )
                                }
                                placeholder='Receivers...'
                                options={this.context.terminal.userTypes.map(
                                  role => {
                                    return {
                                      label: ROLES[role].name,
                                      value: role
                                    }
                                  }
                                )}
                              />
                            </FormGroup>
                            <br />
                            <h6
                              style={{
                                color: '#c8c8c8',
                                position: 'absolute',
                                bottom: '5%',
                                left: '4%'
                              }}
                            >
                              RECEIVERS:
                              <b style={{ color: '#838383' }}>
                                {this.state.loading
                                  ? '...'
                                  : this.state.activeUsers}
                              </b>
                            </h6>
                            <Button
                              style={{
                                position: 'absolute',
                                bottom: '5%',
                                right: '4%'
                              }}
                              disabled={
                                !dirty ||
                                isSubmitting ||
                                !isValid ||
                                this.state.activeUsers.length < 1
                              }
                              type='submit'
                              bsStyle='warning'
                              fill
                              wd
                            >
                              <b>SEND </b>
                              <i className='fa fa-send' />
                            </Button>
                          </>
                        </form>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Card
                      title='History'
                      content={
                        <ReactTable
                          previousText='Previous'
                          nextText='Next'
                          loadingText='Loading...'
                          noDataText='No notifications'
                          pageText='Page'
                          ofText='of'
                          rowsText='Notifications'
                          data={this.state.data}
                          loading={this.state.loading}
                          defaultSorted={[
                            {
                              id: 'createdAt',
                              desc: true
                            }
                          ]}
                          filterable={false}
                          defaultPageSize={10}
                          showPaginationBottom
                          className='-striped -highlight'
                          columns={[
                            {
                              Header: 'Date',
                              accessor: 'createdAt',
                              width: 130,
                              Cell: ({ row }) => {
                                const date = moment(
                                  row.createdAt.toDate()
                                ).format('DD/MM/YYYY HH:mm')
                                return date
                              },
                              getProps: (state, rowInfo, column) => {
                                return {
                                  style: {
                                    fontFamily: 'Monospace',
                                    letterSpacing: '-1px'
                                  }
                                }
                              }
                            },
                            {
                              Header: 'Receivers',
                              accessor: 'user',
                              width: 140,
                              Cell: ({ row }) => {
                                if (row.user !== 'all') {
                                  return (
                                    <Link to={'/user/' + row.user}>
                                      <Badge>{row.user}</Badge>
                                    </Link>
                                  )
                                } else {
                                  return (
                                    <Badge>
                                      {ROLES[row._original.roleReceivers]
                                        ?.name || 'ALL'}
                                    </Badge>
                                  )
                                }
                              }
                            },
                            {
                              Header: <span>Title</span>,
                              accessor: 'titleEN'
                            },
                            {
                              Header: <span>Message</span>,
                              accessor: 'bodyEN'
                            },
                            {
                              Header: 'Receivers',
                              accessor: 'receivers',
                              style: {
                                fontFamily: 'Monospace',
                                fontWeight: 'bold',
                                textAlign: 'right'
                              }
                            }
                          ]}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Grid>
            )
          }}
        </Formik>
      </div>
    )
  }
}

export default Notifications
