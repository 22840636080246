import React, { useContext } from 'react'
// Axios
import axios from 'axios'
// React Bootstrap
import {
  Modal,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Select from 'react-select'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'
// Config
import { ROLES } from 'config/roles'
// Context
import UserContext from 'context/Context'

const STRINGS_INPUTS = [
  { name: 'Passport', value: 'passport', width: 6, upperCase: true },
  { name: 'Email', value: 'email', width: 6 },
  { name: 'Name', value: 'name', width: 6 },
  { name: 'Surname', value: 'surname', width: 6 },
  { name: 'Phone Number', value: 'phoneNumber', width: 6 },
  { name: 'Company Email', value: 'companyEmail', width: 6 },
  { name: 'Company Name', value: 'companyName', width: 12 }
]

const TIME_INPUTS = [
  { name: 'Date of Birth', value: 'dateOfBirth', width: 6 },
  { name: 'Expiration Day', value: 'expirationDay', width: 6 }
]

export const NewUserModal = componentProps => {
  const context = useContext(UserContext)
  return (
    <Formik
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const formatedValues = values
        formatedValues.visitorType = values.visitorType.value
        setSubmitting(true)
        axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/publicAPI/${context.user.terminal}/createOrUpdateUser`, formatedValues, {
          'Content-Type': 'application/json'
        }).then(result => {
          setSubmitting(false)
          componentProps.onClose()
          setTimeout(() => {
            componentProps.updateUsers()
          }, 2000)
          console.log(result)
        }).catch(err => {
          setSubmitting(false)
          console.log(err)
        })
      }}
      validationSchema={Yup.object().shape({
        passport: Yup.string()
          .required(),
        email: Yup.string()
          .email()
          .required(),
        visitorType: Yup.string()
          .required()
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isValid,
          isSubmitting,
          handleBlur,
          handleSubmit,
          setFieldValue
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <Modal
              backdrop='static'
              keyboard={false}
              show={componentProps.show}
              onHide={() => componentProps.onClose()}
            >
              <Modal.Header closeButton>
                <Modal.Title>New user</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row fluid>
                  <Col xs={12} md={12}>
                    <FormGroup validationState={errors.body && touched.body ? 'error' : ''}>
                      <ControlLabel style={{ width: '100%' }}>
                        Visitor Type
                      </ControlLabel>
                      <Select
                        className='react-select primary'
                        classNamePrefix='react-select'
                        name='typeSelect'
                        value={values.visitorType}
                        onChange={option => setFieldValue('visitorType', option)}
                        options={context.terminal.userTypes.map(role => {
                          return {
                            label: ROLES[role].name,
                            value: role
                          }
                        })}
                        placeholder='Select type'
                      />
                    </FormGroup>
                  </Col>
                  {STRINGS_INPUTS.map(item => {
                    return (
                      <Col key={item.value} xs={12} md={item.width}>
                        <FormGroup validationState={errors.title && touched.title ? 'error' : ''}>
                          <ControlLabel style={{ width: '100%' }}>
                            {item.name}
                          </ControlLabel>
                          <FormControl
                            id={item.value}
                            value={values[item.value]}
                            onChange={e => {
                              if (item?.upperCase) {
                                setFieldValue(item.value, e.target.value.toUpperCase())
                              } else {
                                setFieldValue(item.value, e.target.value)
                              }
                            }}
                            onBlur={handleBlur}
                            placeholder={item.name + '...'}
                          />
                        </FormGroup>
                      </Col>
                    )
                  })}
                  {TIME_INPUTS.map(item => {
                    return (
                      <Col key={item.value} xs={12} md={item.width}>
                        <FormGroup validationState={errors.title && touched.title ? 'error' : ''}>
                          <ControlLabel style={{ width: '100%' }}>
                            {item.name}
                          </ControlLabel>
                          <Datetime
                            closeOnSelect
                            timeFormat={false}
                            inputProps={{ placeholder: item.name + '...' }}
                          />
                        </FormGroup>
                      </Col>
                    )
                  })}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !isValid}
                  type='submit'
                  bsStyle='warning'
                  fill
                  wd
                >
                  {isSubmitting ? (
                    <i className='fa fa-spin fa-spinner' />
                  ) : (
                    <b>CREATE USER </b>
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
          </form>
        )
      }}
    </Formik>

  )
}
