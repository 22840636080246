import React from 'react'
import UserContext from 'context/Context'

class ManualInduction extends React.Component {
  static contextType = UserContext;
  componentDidMount () {
    this.props.history.goBack()
    window.open(`https://visitor-hsse.estudiocactus.com/?terminal=${this.context.user.terminal}&language=en`)
  }
  render () {
    return null
  }
}

export default ManualInduction
