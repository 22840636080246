import React, { Component } from 'react'
import moment from 'moment'
import ReactTable from 'react-table'
import { Grid, Row, Col, Badge } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton.jsx'
import Card from 'components/Card/Card.jsx'
import axios from 'axios'
import UserContext from 'context/Context'
import { Link } from 'react-router-dom'
import { CSVLink } from 'react-csv'

class Penalties extends Component {
  static contextType = UserContext;
  state = {
    data: [],
    loading: true,
    filters: { 'penalties.0': { $exists: true } },
    sort: { createdAt: -1 },
    deletingPenalty: false,
    csvData: []
  }

  componentDidMount () {
    this.csvLink = React.createRef()
    document.body.classList.add('sidebar-mini')

    this.updateUsers()
  }

  componentWillUnmount () {
    document.body.classList.remove('sidebar-mini')
  }

  updateUsers = () => {
    this.setState({ loading: true })

    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`, {
      filters: this.state.filters,
      sort: this.state.sort
    })
      .then(res => {
        const penalties = []
        res.data.results.forEach(user => {
          user.penalties.forEach(penalty => penalties.push({
            ...penalty,
            name: user.name,
            passport: user.passport,
            company: user.company
          }))
        })
        this.setState({
          data: penalties,
          loading: false
        })
      })
  }

  deletePenalty = (passport, id) => {
    this.setState({ deletingPenalty: id })
    axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/deletePenalty`, {
      passport: passport,
      id: id
    })
      .then(res => {
        this.setState({
          deletingPenalty: false
        }, () => this.updateUsers())
      })
  }

  render () {
    return (
      <div className='main-content'>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <ReactTable
                    previousText='Previous'
                    nextText='Next'
                    loadingText='Loading...'
                    noDataText='No penalties'
                    pageText='Page'
                    ofText='of'
                    rowsText='penalties'
                    data={this.state.data}
                    loading={this.state.loading}
                    defaultSorted={[
                      {
                        id: 'createdAt',
                        desc: true
                      }
                    ]}
                    filterable
                    defaultPageSize={20}
                    showPaginationBottom
                    className='-striped -highlight'
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'createdAt',
                        width: 130,
                        Cell: ({ row }) => {
                          const date = moment(row.createdAt).format('DD/MM/YYYY HH:mm')
                          return date
                        },
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              fontFamily: 'Monospace',
                              letterSpacing: '-1px'
                            }
                          }
                        }
                      },
                      {
                        Header: 'Passport',
                        accessor: 'passport',
                        width: 120,
                        Cell: ({ row }) =>
                          <Link to={'/user/' + row.passport}>
                            <Badge>{row.passport}</Badge>
                          </Link>
                      },
                      {
                        Header: 'Name',
                        accessor: 'name',
                        width: 240,
                        Cell: row => {
                          return <Link style={{ fontWeight: 'bold', color: '#444' }} to={'/user/' + row.original.passport}>{row.value}</Link>
                        }
                      },
                      {
                        Header: 'Company',
                        accessor: 'company',
                        width: 260
                      },
                      {
                        Header: 'Type',
                        accessor: 'type',
                        width: 100,
                        style: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Repetitiveness',
                        accessor: 'repeat',
                        width: 140
                      },
                      {
                        Header: 'Points',
                        accessor: 'points',
                        width: 120
                      },
                      {
                        Header: 'Delete',
                        style: {
                          textAlign: 'right'
                        },
                        filterable: false,
                        sortable: false,
                        Cell: row =>
                          <Button
                            onClick={() => {
                              this.deletePenalty(row.original.passport, row.original._id)
                            }} bsSize='xs' simple
                          >
                            {this.state.deletingPenalty === row.original._id
                              ? <i className='fa fa-spin fa-spinner' />
                              : <i className='fa fa-close text-danger' />}

                          </Button>
                      }
                    ]}
                  />
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ float: 'right' }}
                simple
                bsStyle='info'
                onClick={async () => {
                  const res = await axios.post(`https://us-central1-apm-terminals-hsse.cloudfunctions.net/api/${this.context.user.terminal}/users`, {
                    filters: this.state.filters,
                    sort: this.state.sort
                  })

                  const penalties = []
                  res.data.results.forEach(user => {
                    user.penalties.forEach(penalty => penalties.push({
                      ...penalty,
                      name: user.name,
                      passport: user.passport,
                      company: user.company
                    }))
                  })

                  this.setState({
                    csvData: penalties.map(item => {
                      const itemCopy = item
                      delete itemCopy._id
                      delete itemCopy.user
                      delete itemCopy.timestamp
                      return itemCopy
                    })
                  }, () => {
                    this.csvLink.current.link.click()
                  })
                }}
              >Download CSV
              </Button>
              <CSVLink
                separator=';'
                data={this.state.csvData}
                filename='visits.csv'
                ref={this.csvLink}
                target='_blank'
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default Penalties
